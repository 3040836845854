const API = $("#AppConfig_WaWInfraPaths_BCService").val();
const BCAPI = $("#AppConfig_WaWInfraPaths_BCService").val();
const COMMON_PRODUCT = 'COMMON';
const COURSE_PRODUCT = 'COURSE';
const COURSE_WITH_OPTIONS = 'PARENT';
const NONMEMBER_CUSTOMER_GROUP_ID = 1;

var hideImageOnMobile = false;
window.certificationIds = [];
window.curriculumCategoryId = 0;
window.allCategories = [];
window.mainProductIds = [];
window.productsAddedToCart = [];
let addToCartCommonApp = null;

$(document).ready(function () {
    if (typeof productsTobeSetup !== "undefined" && productsTobeSetup.length > 0) {
        productsTobeSetup.forEach(productsModuleSetup);
        addToCartCommonApp = addToCartApp().mount('#dynamicAddToCart');
    }

    if (typeof upsellProductContainersTobeSetup !== "undefined" && upsellProductContainersTobeSetup.length > 0) {
        upsellProductContainersTobeSetup.forEach(upsellProductContainersSetup);
    }

    $(document).on('click', '.upsellProductCard', function (e) {

        var targetClassesToIgnore = ["add-to-cart", "details", "quantitySelect", "quantityTextbox", "quantitySelectOption"];

        if (!targetClassesToIgnore.some(s => $(e.target).hasClass(s))) {
            var upsellProductId = $(this).attr('data-productid');
            var bundleMainProductId = $(this).attr('data-bundleMainProductId');
            var isSelected = $(this).find(".checkbox").hasClass("selected");

            if (isSelected) {
                $(this).find(".checkbox").removeClass("selected");
            } else {
                $(this).find(".checkbox").addClass("selected");
            }

            upsellProductContainersMountedApp['id_' + bundleMainProductId].changeBundle(upsellProductId, !isSelected);
        }
    });

    $('#proceed-btn').click((evt) => {
        removeMembershipEvent(evt);
    });
});

const removeMembershipEvent = async (evt) => {
    let cartId = window.getCartId();
    let isCartIdPresent = cartId !== undefined && cartId !== null && cartId != '';
    if (isCartIdPresent == false) return;

    var myModalEl = document.getElementById('BuyMembershipWarning');
    var myModal = bootstrap.Modal.getInstance(myModalEl);
    myModal.hide();

    let result = await deleteCartMembership(evt.target.dataset.removeId);
    if (result == null || result.data == null || result.length <= 0 || result.data.length <= 0) {
        clearCartData();
    }

    $('.loading-overflow-add-cart').hide();
    $("#buyMembership_" + evt.target.dataset.productId + " .add-to-cart").click();
    var attributes = $("#buyMembership_" + evt.target.dataset.productId + " .add-to-cart")[0].attributes;
    let tempApp = addToCartMountedApp['id_' + evt.target.dataset.productId];
    tempApp.openAddtoCartPopup(attributes, evt.target.dataset.productId);
};

const deleteCartMembership = async (id) => {
    $('.loading-overflow-add-cart').show();
    return getCartData().then(async (cart) => {
        const isMembership = cart.some(e => e.id === id);
        const pid = localStorage.getItem('pid') || 0;

        if (isMembership) {
            localStorage.removeItem('hasMembershipInCart');
        }

        if (isMembership && Number(pid) > 0 && customerIsProvisionalMember) {
            await removeCustomerMembership();
        }

        try {
            if (Ecommerce.isReady) {
                var productId = "";
                var element = $("div[data-id='" + id + "']");
                productId = element[0].dataset.productid;

                if (productId && productId != "") {

                    var productInCurrentCart = cart.find(f => f.product_id == productId);

                    Ecommerce.findProductAndSend(cart, productId, "productRemoveFromCart", productInCurrentCart.quantity);
                }
            }
        }
        catch (ex) {

        }

        const deleteCartDataRequest = async () => {
            return new Promise((resolve) => {
                getBCAccessToken().then((accessToken) => {
                    let cartId = window.getCartId();
                    $.ajax({
                        url: `${API}/api/carts/${cartId}/items/${id}`,
                        type: "DELETE",
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                        },
                        complete: (data) => {
                            resolve(data);
                        }
                    });
                });
            });
        }

        var result = await deleteCartDataRequest();

        return result;
    });
};

const clearCartData = () => {
    localStorage.removeItem('bcCartId');
    localStorage.removeItem('parentThatHaveExam');
    localStorage.removeItem('examsInCart');
    mainProductIds.forEach(f => {
        addToCartMountedApp['id_' + f].cartId = "";
    });
};

const getCartData = () => {
    return new Promise((resolve) => {
        getBCAccessToken().then((accessToken) => {
            let cartId = window.getCartId();
            $.ajax({
                url: `${API}/api/carts/${cartId}`,
                type: "GET",
                dataType: "json",
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                },
                complete: (data) => {
                    let cartItems = [];

                    if (data.data) {
                        cartItems = [
                            ...data.data.line_items.custom_items,
                            ...data.data.line_items.digital_items,
                            ...data.data.line_items.physical_items,
                        ]
                    }

                    resolve(cartItems);
                }
            });
        });
    });
}

const deliveryText = (string) => {
    if (!string || string.trim().length <= 0) {
        hideImageOnMobile = true;
        return '';
    }

    if (string === "Virtual Course") {
        return 'Virtual Classroom';
    } else if (string === "E-Learning") {
        return 'eLearning / LMS';
    } else if (string === "CBT Exam") {
        return 'Exam';
    } else if (string === "Class") {
        return 'Classroom';
    } else if (string.toLowerCase() === "Live Digital Event".toLowerCase()) {
        return 'Live Digital Event';
    } else if (string === "Webinar Live") {
        return 'Webinar';
    } else if (string === "Download only") {
        hideImageOnMobile = true;
        return 'Download only';
    } else if (string === "Hardcopy & Download") {
        hideImageOnMobile = true;
        return 'Hardcopy & Download';
    } else if (string === "Hardcopy only") {
        hideImageOnMobile = true;
        return 'Hardcopy only';
    }

    return 'General';
}

window.addParentToStorage = (cart) => {
    if (!cart) {
        localStorage.removeItem('parentThatHaveExam');
        return;
    }

    const cartItems = [
        ...cart.line_items.digital_items,
        ...cart.line_items.custom_items,
        ...cart.line_items.physical_items
    ]

    let filteredItems = [];

    cartItems.forEach(item => {
        const parent = cartItems.find(e => e.id === item.parent_id);

        if (parent) {
            filteredItems.push(parent);
        }
    })

    localStorage.setItem('parentThatHaveExam', JSON.stringify(filteredItems));

    if (filteredItems.length <= 0) {
        localStorage.removeItem('parentThatHaveExam');
    }
}

window.addExamInCart = (exam, parent) => {
    let items = localStorage.getItem('examsInCart')
        ? JSON.parse(localStorage.getItem('examsInCart'))
        : [];

    let pickListIds = [];

    parent.options[0]?.option_values.forEach((e) => {
        if (e?.value_data?.product_id) {
            pickListIds.push(e?.value_data?.product_id);
        }
    });

    const payload = {
        id: exam.id,
        removeIds: pickListIds
    }

    if (!items.some(e => e.id === exam.id)) {
        items.push(payload);

        localStorage.setItem('examsInCart', JSON.stringify(items));
    }
}

window.getCartId = () => {
    let tempCartId = localStorage.getItem('bcCartId')?.length > 0 ? localStorage.getItem('bcCartId') : "";

    if (tempCartId !== undefined && !tempCartId?.length > 0) {

        mainProductIds.forEach(f => {

            let appCartId = addToCartMountedApp['id_' + f].cartId;

            if (appCartId.length > 0) {
                tempCartId = appCartId;
            }
        });
    }

    return tempCartId;
}

function addToCartApp(item) {
    return Vue.createApp({
        data() {
            return {
                cartId: "",
                isUserLoggedIn: window.wawUser.id != null,
                isLoading: true,
                productApp: null,
                hidePopup: false,
                bundleMainProductId: "",
                mainProductId: "",
                isCommonApp: false,
                wawUser: window.wawUser,
                isDonationProduct: item?.isDonationProduct ?? false
            }
        },

        methods: {

            openLoginAccessDenied(event) {
                openLoginAccessDenied(event);
            },

            clearCartData() {
                localStorage.removeItem('bcCartId');
            },

            async openAddtoCartPopup(attributes, mainProductId) {

                if (this.isDonationProduct) {
                    this.clearCartData();
                }

                $('.loading-overflow-add-cart').show();

                return new Promise((resolve, reject) => {
                    (async () => {
                        this.cartId = window.getCartId();

                        let verifyCartItems = await this.verifyCartRequest();

                        this.mainProductId = mainProductId;
                        this.productApp = productsMountedApp['id_' + mainProductId];
                        //let attributes = event.target.attributes;

                        this.hidePopup = attributes['data-hidePopup']?.value == 'true';
                        this.bundleMainProductId = attributes['data-bundleMainProductId']?.value;

                        if (!this.hidePopup) {
                            $('body').addClass('cart-modal-opened');
                        }

                        let payload = {};
                        let hasExam = false;
                        let courseType = 'COMMON';
                        let productId = Number(attributes['productId']?.value);
                        let childrenId = Number(attributes['childrenId']?.value);
                        let examId = Number(attributes['examId']?.value);
                        let variantId = attributes['variantId']?.value;
                        let name = attributes['productName']?.value;
                        let parentName = attributes['parentProductName']?.value;
                        let examProductName = attributes['examProductName']?.value;
                        let quantity = attributes['quantity']?.value ? attributes['quantity']?.value : 1;
                        let delivery = deliveryText(attributes['delivery']?.value);
                        let date = attributes['date']?.value;
                        let place = attributes['place']?.value;
                        let isCourse = attributes['isCourse']?.value;
                        let isMultiYearMembership = false;
                        let productAdded = {};

                        let details = [];

                        localStorage.setItem('lastShoppingPage', window.location.href);

                        if (name.startsWith("Professional Membership")) {
                            if (typeof (membershipProducts) != "undefined") {
                                var currentMemProduct = membershipProducts.filter(x => x.productCode.includes(this.productApp.productData.sku))[0];
                                isMultiYearMembership = currentMemProduct?.years > 1;
                            }

                            if (verifyCartItems && verifyCartItems.length > 0) {
                                var otherMembershipProductExistinCart = verifyCartItems.filter(x => x.product_id != productId && x.name.startsWith("Professional Membership"));

                                if (otherMembershipProductExistinCart.length > 0) {
                                    $("#BuyMembershipWarning #proceed-btn").attr("data-product-id", productId);
                                    $("#BuyMembershipWarning #proceed-btn").attr("data-remove-id", otherMembershipProductExistinCart[0].id);
                                    var membershipYears = $("#buyMembership_" + otherMembershipProductExistinCart[0].product_id + " .plan-name").attr("data-year");
                                    $("#BuyMembershipWarning .modal-body .desc").html("A <span class='highlight-name'>" + membershipYears + "-Year Membership</span> product already exists in the cart. Adding this will replace the existing membership product in the cart.");
                                    var myModal = new bootstrap.Modal(document.getElementById('BuyMembershipWarning'))
                                    myModal.show();
                                    $('body').removeClass('cart-modal-opened');
                                    $('.loading-overflow-add-cart').hide();
                                    return;
                                }
                            }
                        }

                        if (delivery != 'General') {
                            details.push(delivery);
                        }

                        if (delivery === 'Classroom') {
                            details.push(place)
                            details.push(date)
                        }

                        if (delivery === 'Virtual Classroom') {
                            details.push(date)
                        }

                        if (delivery === 'eLearning / LMS') {
                            details.push('120-Day Access')
                        }

                        if (delivery === 'Webinar' || delivery === "Live Digital Event") {
                            details.push(date)
                        }

                        if (delivery === 'General') {
                            if (date.trim().length > 0) {
                                details.push(place)
                                details.push(date)
                            }
                        }

                        if (childrenId) {
                            if (examId) {
                                window.addExamInCart(this.productApp.examProduct, this.productApp.productData);

                                if (window.restrictTabs) {
                                    var refreshCourse = { id: childrenId };
                                    window.addExamInCart(refreshCourse, this.productApp.productData);
                                }
                            }

                            payload = {
                                "line_items": [
                                    {
                                        "quantity": quantity,
                                        "product_id": childrenId,
                                    }
                                ]
                            }

                            if (examId) {
                                payload.line_items.push({
                                    "quantity": quantity,
                                    "product_id": examId,
                                })
                            }

                            const EXAM_IN_THE_CART = '<i>Exam is already in the cart.</i>';

                            const deliveryTypes = ['Classroom', 'Virtual Classroom', 'eLearning / LMS', 'Exam'];

                            if (delivery.includes('Exam')) {
                                payload = {
                                    "line_items": [{
                                        "quantity": quantity,
                                        "product_id": childrenId
                                    }]
                                };
                            }

                            if (examId && isCourse === "true" && verifyCartItems && verifyCartItems.some(e => e.product_id === Number(examId))) {

                                if (!delivery.includes('Exam')) {
                                    details.push(EXAM_IN_THE_CART);
                                }

                                payload = {
                                    "line_items": [{
                                        "quantity": quantity,
                                        "product_id": childrenId
                                    }]
                                };

                                courseType = COURSE_PRODUCT;

                            } else {
                                if (examId) {
                                    deliveryTypes.forEach(item => {
                                        if (delivery && delivery === item) {
                                            hasExam = true;
                                        }
                                    })
                                }

                                courseType = COURSE_WITH_OPTIONS;
                            }

                            productAdded = {
                                name: name,
                                details: details,
                            };

                            if (examId) {
                                productAdded.examName = examProductName;
                            }

                        } else {
                            payload = {
                                "line_items": [{
                                    "quantity": quantity,
                                    "product_id": productId
                                }]
                            };

                            productAdded = {
                                name: name,
                                details: details
                            };

                            courseType = COMMON_PRODUCT;
                        }

                        const pid = localStorage.getItem('pid') || 0;

                        payload.customer_id = pid;

                        let salePrice = 0;
                        let listPrice = 0;
                        let totalPrice = 0;

                        let customerIsNonMember = false;

                        let cartResponse = await this.addToCartRequest(payload);

                        if (cartResponse !== undefined) {

                            productsAddedToCart.push(this.mainProductId);

                            try {
                                if (Ecommerce.isReady) {

                                    var currentProductApp = this.productApp;

                                    $.each(payload.line_items, function (index, value) {
                                        var productId = value.product_id;
                                        var quantity = value.quantity;

                                        var productsArray = [];

                                        if (currentProductApp.childProducts && currentProductApp.childProducts.length > 0) {
                                            productsArray = currentProductApp.childProducts;
                                        }
                                        else {
                                            productsArray.push(currentProductApp.productData);
                                        }

                                        Ecommerce.findProductAndSend(productsArray,
                                            productId,
                                            "productAddToCart",
                                            quantity,
                                            currentProductApp.productData);

                                    });
                                }
                            } catch (ex) {
                            }

                            const productIsMembership = productAdded.name.startsWith("Professional Membership");

                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .membership-message').addClass('hide');
                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .affiliate-msg').addClass('hide');

                            if (productIsMembership) {
                                $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .membership-message').removeClass('hide');

                                if (this.isUserLoggedIn && this.wawUser.isLocalNetworkMember) {
                                    if (isMultiYearMembership) {
                                        if (this.wawUser.isMember) {
                                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .affiliate-msg-multi-year-renewal').removeClass('hide');
                                        } else {
                                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .affiliate-msg-multi-year').removeClass('hide');
                                        }
                                    } else {
                                        if (this.wawUser.isMember) {
                                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .affiliate-msg-renewal').removeClass('hide');
                                        } else {
                                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .affiliate-msg-user').removeClass('hide');
                                        }
                                    }
                                }

                                localStorage.setItem('hasMembershipInCart', 'true');
                                if (Number(pid) > 0) {

                                    this.isMemberRequest().then((customer) => {
                                        //let customer = await this.isMemberRequest();

                                        if (customer?.customer_group_id === NONMEMBER_CUSTOMER_GROUP_ID) {
                                            customerIsNonMember = true;
                                        }

                                        if (customerIsNonMember) {
                                            //await applyCustomerMembership();
                                        } else {
                                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .membership-message').addClass('hide');
                                        }
                                    });
                                }
                            }

                            const cartItems = [
                                ...cartResponse.line_items.custom_items,
                                ...cartResponse.line_items.digital_items,
                                ...cartResponse.line_items.physical_items,
                            ]

                            cartItems.forEach(e => {
                                let idsToGetPrice = [];

                                if (courseType === COMMON_PRODUCT) {
                                    idsToGetPrice = [productId];
                                } else {
                                    idsToGetPrice = [childrenId];

                                    if (courseType === COURSE_WITH_OPTIONS && examId) {
                                        idsToGetPrice = [childrenId, examId];
                                    }
                                }

                                if (idsToGetPrice.includes(e.product_id)) {
                                    salePrice += e.sale_price;
                                    listPrice += e.list_price;
                                    totalPrice += e.sale_price;
                                }
                            });

                            if (salePrice > listPrice) {
                                listPrice = '';
                            }

                            const getProductHtml = (product) => {
                                if (!product) return '';

                                const getDetails = (details) => {
                                    if (!details) return '';

                                    const detailsHtml = details.map(e => `${e}`)

                                    return `<div class="product-type">
                            ${detailsHtml.join('<br/>')}
                            </div>
                    `
                                }

                                return `
                    <div class="product-name">
                        ${product.name}
                    </div>
                    ${getDetails(product.details)}
                
                `
                            }

                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .products-list').html('');
                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .qty').html('');

                            if (hasExam && !productAdded.details.includes('Exam')) {
                                const examProduct = {
                                    name: productAdded.examName,
                                    details: ['Exam']
                                };

                                $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .products-list').append(getProductHtml(examProduct));
                            }

                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .products-list').append(getProductHtml(productAdded));
                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .qty').html('x ' + quantity);

                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .list-price').html('');

                            if (salePrice != listPrice) {
                                $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .list-price').addClass("list-price-strikethrough");
                                $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .list-price').html(listPrice.localizeCurrency());
                            }

                            $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .total-price').html(totalPrice.localizeCurrency());

                            //Set image in add cart modal
                            if (this.productApp.mainProductImage) {
                                $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .product-image').show();
                                if (hideImageOnMobile) {
                                    $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .product-image').addClass("d-none d-md-block");
                                }

                                $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .addcart_modal_img').attr("src", this.productApp.mainProductImage);
                            }
                            else {
                                $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart .product-image').hide();
                            }
                        }

                        $('.loading-overflow-add-cart').hide();

                        resolve(this.cartId);
                    })()
                });
            },

            async verifyCartRequest() {
                return new Promise((resolve) => {
                    if (this.cartId.length > 0) {
                        getBCAccessToken().then((accessToken) => {
                            $.ajax({
                                url: `${BCAPI}/api/carts/${this.cartId}`,
                                type: "GET",
                                dataType: "json",
                                beforeSend: function (xhr) {
                                    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                                },
                                success: (data) => {
                                    let cartItems = [];

                                    if (!data.data) {
                                        localStorage.removeItem('bcCartId');
                                        localStorage.removeItem('examsInCart');
                                        localStorage.removeItem('hasMembershipInCart');
                                    } else {
                                        window.addParentToStorage(data.data);

                                        cartItems = [
                                            ...data.data.line_items.custom_items,
                                            ...data.data.line_items.digital_items,
                                            ...data.data.line_items.physical_items,
                                        ]

                                        let hasMembership = cartItems.some(e => e.name.startsWith("Professional Membership"));

                                        if (!hasMembership) {
                                            localStorage.removeItem('hasMembershipInCart');
                                        }

                                    }
                                    resolve(cartItems);
                                },
                                error: (data) => {
                                    localStorage.removeItem('bcCartId');
                                    localStorage.removeItem('examsInCart');
                                    localStorage.removeItem('hasMembershipInCart');
                                    resolve();
                                }
                            });
                        });
                    } else {
                        localStorage.removeItem('hasMembershipInCart');
                        resolve();
                    }
                });
            },

            async isMemberRequest() {
                const pid = localStorage.getItem('pid') || 0;

                return new Promise((resolve) => {
                    getBCAccessToken().then((accessToken) => {
                        $.ajax({
                            url: `${BCAPI}/api/customers/${pid}`,
                            type: "GET",
                            dataType: "json",
                            beforeSend: function (xhr) {
                                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                            },
                            success: (data) => {
                                resolve(data.data[0]);
                            },
                            error: (data) => {
                                resolve();
                            }
                        });
                    });
                });
            },

            async addToCartRequest(payload) {
                return new Promise((resolve, reject) => {

                    if (payload.customer_id && payload.customer_id != 0) {
                        $.ajax({
                            url: "/profile/bc-update-membership",
                            type: "POST",
                            dataType: "json",
                            async: false,
                            data: {
                                personId: payload.customer_id
                            }
                        });
                    }

                    getBCAccessToken().then((accessToken) => {
                        $.ajax({
                            url: `${BCAPI}/api/carts${this.cartId.length > 0 ? '/' + this.cartId + '/additem' : ''}`,
                            type: "POST",
                            dataType: "json",
                            async: false,
                            data: JSON.stringify(payload),
                            beforeSend: function (xhr) {
                                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                            },
                            contentType: 'application/json; charset=utf-8',
                            success: (data) => {
                                if (data && data.data && data.data.id) {
                                    if (!this.hidePopup) {
                                        $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart').show();
                                    }

                                    localStorage.setItem('bcCartId', data.data.id);
                                    this.cartId = data.data.id;

                                    try {
                                        document.cookie = "cartid=" + data.data.id + "; SameSite=Strict; path=/; Secure;";
                                    } catch (ex) {

                                    }

                                    window.addParentToStorage(data.data);

                                    if (!this.isDonationProduct) {
                                        this.updateLoginLinks(data);
                                    }

                                } else {
                                    $("[data-product-id=" + this.mainProductId + "]").find(".cart-full").show();
                                    $('body').removeClass('cart-modal-opened');

                                    if (this.productApp.productData.name.startsWith("Professional Membership") && document.getElementById('BuyMembershipError')) {
                                        var myModal = new bootstrap.Modal(document.getElementById('BuyMembershipError'));
                                        myModal.show();
                                    }
                                }

                                resolve(data.data);
                            },
                            error: (data) => {
                                reject(data);
                            }
                        });
                    });
                });
            },

            async showAddToCart(isCommonApp) {
                return new Promise((resolve, reject) => {

                    if (isCommonApp && productsAddedToCart.length > 0) {

                        this.isCommonApp = isCommonApp;

                        $('#dynamicAddToCart #add-to-cart').show();

                        $('#dynamicAddToCart #add-to-cart .modal-body-content .product-row').remove();

                        productsAddedToCart.forEach(f => {
                            $('#dynamicAddToCart_' + f + ' #add-to-cart .modal-body-content .product-row').clone().insertBefore($('#dynamicAddToCart #add-to-cart .modal-body-content .member-msg'));
                        });

                        productsAddedToCart = [];
                    }

                    resolve();
                });
            },

            updateLoginLinks(data) {
                if (data.data.id) {
                    let items = [];

                    if (data.data.line_items) {
                        Object.keys(data.data.line_items).forEach(key => {
                            if (data.data.line_items[key].length > 0) {
                                data.data.line_items[key].filter(x => {
                                    return x.sale_price >= 0 && !x.hide
                                }).forEach(item => {
                                    items.push(item);
                                });
                            }
                        });
                    }

                    localStorage.setItem('cartLength', items.length);

                    $('.login-link').each((i, obj) => {
                        let href = $(obj).attr('href');
                        let defaultHref = href.split('&')[0];
                        const BC_CART_ID = localStorage.getItem('bcCartId');

                        if (BC_CART_ID) {
                            defaultHref = defaultHref + '?cartId=' + BC_CART_ID;
                        }

                        const HAS_MEMBERSHIP_IN_CART = localStorage.getItem('hasMembershipInCart');

                        if (HAS_MEMBERSHIP_IN_CART === 'true') {
                            if (defaultHref.includes('?')) {
                                defaultHref = defaultHref + '&membership=true';
                            } else {
                                defaultHref = defaultHref + '?membership=true';
                            }
                        }

                        $(obj).attr('href', defaultHref);
                    });

                    updateCartLinkInMenu();
                }
            },

            closeAddToCart() {
                $('#dynamicAddToCart_' + this.mainProductId + ' #add-to-cart').hide();
                $('body').removeClass('cart-modal-opened');
                $('body').removeClass('learning-methods-modal-opened');
                $('body').removeClass('details-modal-opened');
                $('body').removeClass('contact-sponsor-modal-opened');
                if (this.isCommonApp) {
                    $('#dynamicAddToCart #add-to-cart').hide();
                }
            }
        },

        created() {
        }
    });
}

function productsModuleSetup(item) {

    if (item.isProductDetailAvailable) {
        initializeProductDetailsPopup(item.productId);
    }

    let productsApp = Vue.createApp({
        data() {
            return {
                item: item,
                mainProductId: item.productId,
                mainProductType: item.type,
                mainProductImage: item.productImage,
                includeExam: item.includeExam === undefined ? true : item.includeExam,
                inputExamProductId: item.includeExam === undefined ? "" : ((item.includeExam ?? true) && item.inputExamProductId) ? item.inputExamProductId : "",
                productData: null,
                childProducts: [],
                showQuantity: false,
                productTypes: {
                    isCourse: false,
                    addExamBundled: false,
                    hasVirtualClassRoom: false,
                    hasELearning: false,
                    hasClassroom: false,
                    hasExam: false,
                    hasPracticeExam: false
                },
                displayProducts: [],
                chosenProductId: null,
                chosenProduct: null,
                chosenProductQuantity: item.chosenProductQuantity ?? 1,
                examProduct: null,
                memberPriceList: [],
                nonMemberPriceList: [],
                memberPriceHtml: null,
                nonMemberPriceHtml: null,
                buyButtonHtml: null,
                registerButtonHtml: null,
                Months: [],
                States: [],
                Countries: [],
                selectedTab: item.selectedTab,
                selectedMonth: "",
                selectedState: "",
                selectedCountry: "",
                currentProductDetailsApp: productDetailsMountedApp['id_' + item.productId],
                currentPageUrl: window.location.href.split('?')[0],
                devEnv: ['qa.', 'dev.', 'localhost'].some(e => window.location.hostname.includes(e)),
                isLoading: true,
                filtersApplied: false,
                bundleMainProductId: item.bundleMainProductId,
                isDonationProduct: item.isDonationProduct,
                isUserLoggedIn: window.wawUser.id != null,
                domId: item.id,
                restrictTabs: typeof (window.restrictTabs) != "undefined" && window.restrictTabs,
                dummyTypesList: typeof (window.dummyTypesList) == "undefined" ? [] : window.dummyTypesList,
                AllMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                filtersAvailable: false,
                filterPivot: ""
            }
        },

        methods: {

            async addToCart(event) {
                event.preventDefault();

                if (this.isDonationProduct && !this.isUserLoggedIn) {
                    this.openLoginAccessDenied(event);
                }
                else {
                    let currentCartId = await addToCartMountedApp['id_' + this.mainProductId].openAddtoCartPopup(event.target.attributes, this.mainProductId);

                    if (this.isDonationProduct) {
                        this.setCookieandRedirectToCartForDonationProduct(currentCartId);
                    }
                }
            },

            setCookieandRedirectToCartForDonationProduct(cartId) {
                var url = window.location.origin
                    + "/cart"
                    + "?cartId=" + cartId
                    + "&redirectToCheckout=1";

                try {
                    document.cookie = "isDonationProductinCart=true; SameSite=Strict; path=/; Secure;";
                } catch (ex) {

                }
                $('.loading-overflow-add-cart').show();
                window.location.replace(url);
            },

            openLoginAccessDenied(event) {
                openLoginAccessDenied(event);
            },

            addToCartClickMethod() {
                return "productsMountedApp['id_" + this.mainProductId + "'].addToCart(event)";
            },

            isNullOrWhitespace(input) {
                return isNullOrWhitespace(input);
            },

            async getProductInfoBC(id, includeChild) {
                return new Promise((resolve, reject) => {
                    getBCAccessToken().then((accessToken) => {
                        $.ajax({
                            url: `${API}/api/products/${id}?includeChild=${includeChild}`,
                            type: "GET",
                            dataType: "json",
                            beforeSend: function (xhr) {
                                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                            },
                            success: (data) => {
                                resolve(data);
                            },
                            error: (data) => {
                                reject(data)
                            }
                        });
                    });
                });
            },

            async getCategories() {
                return new Promise((resolve) => {
                    getBCAccessToken().then((accessToken) => {
                        $.ajax({
                            url: `${API}/api/categories`,
                            type: "GET",
                            dataType: "json",
                            beforeSend: function (xhr) {
                                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                            },
                            success: (data) => {
                                if (data.data) {
                                    const category = data.data.filter(e => ["Curriculum", "Learning"].includes(e.name));

                                    const curriculum = data.data.find(e => e.name === 'Curriculum');

                                    window.curriculumCategoryId = curriculum?.id;

                                    window.certificationIds = category.map(e => e.id);

                                    window.allCategories = data.data;
                                }
                            },
                            error: (data) => {
                                console.log(data);
                            },
                            complete: (data) => {
                                resolve(data);
                            }
                        });
                    });
                });
            },

            priceListRequest(products) {

                let ids = products.map(e => e.id);
                ids.push(this.productData.id);

                return new Promise((resolve, reject) => {
                    getBCAccessToken().then((accessToken) => {
                        $.ajax({
                            url: `${API}/api/pricelists/records/Member%20Price`,
                            type: "GET",
                            dataType: "json",
                            headers: {
                                'product_id': ids.join(',')
                            },
                            beforeSend: function (xhr) {
                                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                            },
                            success: (data) => {
                                this.memberPriceList = [...data.data, ...this.memberPriceList];
                                resolve();
                            },
                            error: (data) => {
                                reject();
                            }
                        });
                    });
                });
            },

            nonMemberPriceListRequest(products) {

                let ids = products.map(e => e.id);
                ids.push(this.productData.id);

                return new Promise((resolve, reject) => {
                    getBCAccessToken().then((accessToken) => {
                        $.ajax({
                            url: `${API}/api/pricelists/records/Non-Member`,
                            type: "GET",
                            dataType: "json",
                            headers: {
                                'product_id': ids.join(',')
                            },
                            beforeSend: function (xhr) {
                                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                            },
                            success: (data) => {
                                this.nonMemberPriceList = [...data.data, ...this.nonMemberPriceList];
                                resolve();
                            },
                            error: (data) => {
                                reject();
                            }
                        });
                    });
                });
            },

            getTabName(delivery) {
                let tabName = "";

                if (delivery == "Virtual Course" || delivery == "1310 - Virtual Classroom") {
                    tabName = "virtual";
                } else if (delivery == "E-Learning" || delivery == "1210 - E-Learning") {
                    tabName = "elearning";
                } else if (delivery == "CBT Exam" || delivery == "1340 - Examination Only") {
                    tabName = "exam";
                } else if (delivery == "Practice Exam" || delivery == "1350 - Other Self Study") {
                    tabName = "practice-exam";
                } else if (delivery === "Class" || delivery == "1000 - Onsite Course") {
                    tabName = "classroom";
                }

                return tabName;
            },

            getBCTabName(activeTab) {
                let tabName = "";

                if (activeTab == "virtual") {
                    tabName = "1310 - Virtual Classroom";
                } else if (activeTab == "elearning") {
                    tabName = "1210 - E-Learning";
                } else if (activeTab == "exam") {
                    tabName = "1340 - Examination Only";
                } else if (activeTab == "practice-exam") {
                    tabName = "1350 - Other Self Study";
                } else if (activeTab == "classroom") {
                    tabName = "1000 - Onsite Course";
                }

                return tabName;
            },

            isTabAvailable(tab) {
                let tabFlag = false;
                if (tab == "virtual") {
                    tabFlag = this.productTypes.hasVirtualClassRoom;
                } else if (tab == "elearning") {
                    tabFlag = this.productTypes.hasELearning;
                } else if (tab == "exam") {
                    tabFlag = this.productTypes.hasExam;
                } else if (tab == "practice-exam") {
                    tabFlag = this.productTypes.hasPracticeExam;
                } else if (tab == "classroom") {
                    tabFlag = this.productTypes.hasClassroom;
                }

                return tabFlag;
            },

            getInitialTab() {
                let tabName = "";
                if (this.productTypes.hasVirtualClassRoom) {
                    tabName = "1310 - Virtual Classroom";
                } else if (this.productTypes.hasClassroom) {
                    tabName = "1000 - Onsite Course";
                } else if (this.productTypes.hasELearning) {
                    tabName = "1210 - E-Learning";
                } else if (this.productTypes.hasExam) {
                    tabName = "1340 - Examination Only";
                } else if (this.productTypes.hasPracticeExam) {
                    tabName = "1350 - Other Self Study";
                }

                return tabName;
            },

            hasDate(start, end) {
                return start != undefined && start.trim().length > 0 && end != undefined && end.trim().length > 0;
            },

            getDate(start, end) {

                let text = '';

                if (start?.trim().length > 0) {
                    text = `${this.getMonth(start)} ${this.getDay(start)}, ${this.getYear(start)}`;

                    if (end?.trim().length > 0 && start !== end) {
                        text += ` - ${this.getMonth(end)} ${this.getDay(end)}, ${this.getYear(end)}`;
                    }
                } else if (end?.trim().length > 0) {
                    text = `${this.getMonth(end)} ${this.getDay(end)}, ${this.getYear(end)}`;
                }

                return text;
            },

            getNADate(string) {
                if (string) {
                    let startCopy = string;

                    var month = startCopy.slice(0, 2);
                    var day = startCopy.slice(2, 4);
                    var year = startCopy.slice(4, 8);

                    month = month - 1;

                    return new Date(year, month, day);
                }

                return new Date('01-01-1900');
            },

            getMonth(date) {

                if (date.trim().length > 0) {
                    let month = date.slice(0, 2);
                    const monthsArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                    return monthsArr[Number(month) - 1];
                }
                return '';
            },

            getWeekday(date) {
                if (!date || date.trim().length === 0) return '';

                let position = 2;
                date = [date.slice(0, position), '-', date.slice(position)].join('');
                position = 5;
                date = [date.slice(0, position), '-', date.slice(position)].join('');

                var options = { weekday: 'long', hour: '2-digit', minute: '2-digit' };

                const realDate = new Date(date).toLocaleTimeString('en-us', options);

                return realDate.split(' ')[0];
            },

            getHour(date) {
                if (!date || date.trim().length === 0) return '';

                let position = 2;
                date = [date.slice(0, position), '-', date.slice(position)].join('');
                position = 5;
                date = [date.slice(0, position), '-', date.slice(position)].join('');

                var options = { weekday: 'long', hour: '2-digit', minute: '2-digit' };

                const realDate = new Date(date).toLocaleTimeString('en-us', options).split(' ');

                return `${realDate[1]} ${realDate[2]}`;
            },

            getDay(date) {
                return date.trim().length > 0 ? date.slice(2, 4) : '';
            },

            getYear(date) {
                return date.trim().length > 0 ? date.slice(4, 8) : '';
            },

            hasPlace(cfs) {
                return cfs.City_C != undefined && cfs.City_C.trim().length > 0
                    && cfs.State_C != undefined && cfs.State_C.trim().length > 0
                    && cfs.Country_C != undefined && cfs.Country_C.trim().length > 0;
            },

            getPlace(cfs) {
                if (this.hasPlace(cfs)) {
                    return `${cfs.City_C}, ${cfs.State_C} ${cfs.Country_C}`
                }

                return ''
            },

            getDelivery(cfs) {
                if (cfs.Delivery && cfs.Delivery.trim().length > 0) {
                    return `${cfs.Delivery}`
                }

                if (this.devEnv) {
                    return '{{ Delivery }}'
                };

                return ''
            },

            getDeliveryText(delivery) {
                return deliveryText(delivery);
            },

            getSponsor(cfs) {
                if (cfs.Sponsor_C && cfs.Sponsor_C.trim().length > 0) {
                    return `${cfs.Sponsor_C}`
                }

                return ''
            },

            getFilterMonth(date) {
                let month = date.slice(0, 2);

                return this.AllMonths[Number(month) - 1];
            },

            verifyCustomField(cf, name) {
                if (cf && cf.trim().length > 0) {
                    return `${cf}`
                }

                if (devEnv) {
                    return `{{ ${name} }}`
                };

                return ''
            },

            getPageTitle(product) {
                if (product.page_title.trim().length > 0) {
                    return `${product.page_title}`
                }

                if (devEnv) {
                    return '{{ product.page_title }}'
                };

                return ''
            },

            openProductDetails(productId, isSponsor) {

                this.currentProductDetailsApp.isContactSponsorPopup = isSponsor;

                try {
                    if (Ecommerce.isReady && productId) {

                        var productsArray = [];

                        if (this.childProducts && this.childProducts.length > 0) {
                            productsArray = this.childProducts;
                        }
                        else {
                            productsArray.push(this.productData);
                        }

                        Ecommerce.findProductAndSend(productsArray, productId, "productClick", undefined, this.productData);
                        Ecommerce.findProductAndSend(productsArray, productId, "productDetail", undefined, this.productData);
                    }
                } catch (ex) {

                }

                $("#productDetails_" + this.mainProductId + " #product-details-popup").modal('show');
                this.currentProductDetailsApp.isLoading = true;
                this.currentProductDetailsApp.productDetail = [];
                this.currentProductDetailsApp.getProductDetailsByBcId(productId);
            },

            isContactSponsor(product) {

                let type = product.cfs.Course_Type_C?.trim() || "";

                if (type == "WorldatWork" || type == "") { //for CSCP and CECP
                    return this.addToCartButton(product);
                } else if (type == "WorldatWork India") {
                    return "";
                } else {
                    return `<div class="d-flex open-details mt-3">
                    <a class="waw-btn ghost primary contact-sponsor open-contact-sponsor" v-on:click="openProductDetails(` + product.id + `, true)">Sponsor Pricing &amp; Registration</a>
                    </div>
                    `
                }
            },

            addToCartButton(product) {

                if (product.inventory_tracking != "none"
                    && product.inventory_level <= 0) {
                    return `
                    <a class="waw-btn disabled">
                        Unavailable
                    </a>
                    <a href="https://worldatwork.org/support/contact-us"  class="waiting">
                        To be placed on the waiting list or inquire about other sessions or products, please contact our Customer Experience Team.
                    </a>
                    `
                }

                if (!this.productTypes.addExamBundled) {
                    return `<div class="d-flex">
                    <a class="waw-btn primary add-to-cart mt-3"
                        href="#"
                        onclick=${this.addToCartClickMethod()}
                        productId="${product.id}" 
                        variantId="${product.variants[0].id}"
                        productName="${product.page_title}"
                        examProductName="${this.examProduct?.page_title}"
                        parentProductName="${this.productData.name}"
                        productPrice="${product.price}"
                        productMemberPrice="${this.memberPriceList.find(e => e.product_id === product.id)?.price}"
                        quantity="1"
                        delivery="${this.getDelivery(product.cfs)}"
                        date="${this.getDate(product.cfs.Product_Start_Date_C, product.cfs.Product_End_Date_C)}"
                        place="${this.getPlace(product.cfs)}">
                        Add to Cart
                    </a>
                    </div>
                    <div class="d-flex">
                        <div class="cart-full" class="mt-3" style="display:none;">
                            <div class="error_msg">Sorry, you can't add this item to the cart.</div>
                            <div class="error_msg">You have reached the maximum allowed quantity for purchase in your cart or the item isn't available anymore.</div>
                        </div>
                    </div>
                    `
                }

                return `<div class="d-flex">
                <a class="waw-btn primary add-to-cart mt-3"
                    href="#"
                    onclick=${this.addToCartClickMethod()}
                    productId="${this.productData.id}" 
                    childrenId="${product.id}"
                    examId="${this.examProduct?.id ? this.examProduct.id : ''}"
                    variantId="${this.productData.variants[0].id}"
                    productName="${product.page_title}"
                    examProductName="${this.examProduct?.page_title}"
                    parentProductName="${this.productData.name}"
                    delivery="${this.getDelivery(product.cfs)}"
                    date="${this.getDate(product.cfs.Product_Start_Date_C, product.cfs.Product_End_Date_C)}"
                    place="${this.getPlace(product.cfs)}"
                    isCourse="${this.productTypes.addExamBundled}">
                    Add to Cart
                </a>
                </div>
                <div class="d-flex">
                    <div class="cart-full" class="mt-3" style="display:none;">
                        <div class="error_msg">Sorry, you can't add this item to the cart.</div>
                        <div class="error_msg">You have reached the maximum allowed quantity for purchase in your cart or the item isn't available anymore.</div>
                    </div>
                </div>
                `
            },

            registerButton(product, btnText) {
                if (product.inventory_tracking != "none" && product.inventory_level <= 0) {
                    return `
                    <a class="waw-btn disabled">
                        Unavailable
                    </a>
                    <a href="https://qa.worldatwork.org/contact-us"  class="waiting">
                        To be placed on the waiting list or inquire about other sessions or products, please contact our Customer Experience Team.
                    </a>
                    `
                }

                if (typeof (easeWebinarRegistration) != "undefined" && easeWebinarRegistration) {
                    if (this.mainProductType == "webinar") {
                        var redirectWebinarUrl = new URL(window.location.href);
                        redirectWebinarUrl.searchParams.append("register", true);

                        if (webinar.member_Only__c && !isMember) {
                            return `
                            <div class="RegisterButton">
                                    <div class="cta">
                                        <a class=""
                                        href="#memberOnlyAccessDenied"
                                        onclick="openMemberOnlyAccessDenied(null, '${redirectWebinarUrl.pathname + redirectWebinarUrl.search}')"
                                        >Member Only Access</a>
                                    </div>
                                </div>`;
                        }
                        else if (!isUserAuthenticated) {
                            return `
                            <div class="RegisterButton">
                                    <div class="cta">
                                        <button class="register-webinar" onclick="openLoginAccessDenied(null, '${redirectWebinarUrl.pathname + redirectWebinarUrl.search}', true)" data-href="${redirectWebinarUrl.pathname + redirectWebinarUrl.search}" aria-label="Log in to Register">Register</button>
                                    </div>
                                </div>`;
                        }
                        else {
                            return `
                            <div class="RegisterButton">
                                    <div class="cta">
                                        <a class="register-webinar" onclick="registerWebinar(${product.id})" productid="${product.id}" aria-label="Register webinar">Register</a>
                                    </div>
                                </div>`;
                        }
                    }
                }
                else {
                    if (this.mainProductType == "webinar" && (webinar.member_Only__c) && !isMember) {
                        return `
                            <div class="RegisterButton">
                                    <div class="cta">
                                        <a class=""
                                        href="#memberOnlyAccessDenied"
                                        onclick="openMemberOnlyAccessDenied(null, '${location.pathname}')"
                                        >Member Only Access</a>
                                    </div>
                                </div>`;
                    }
                }



                return `
                <div class="RegisterButton">
                        <div class="cta">
                            <a class="add-to-cart"
                            onclick=${this.addToCartClickMethod()}
                            productId="${product.id}" 
                            childrenId="${product.id}"
                            examId="${this.examProduct?.id ? this.examProduct.id : ''}"
                            variantId="${product.variants[0].id}"
                            productName="${product.page_title}"
                            examProductName="${this.examProduct?.page_title}"
                            delivery="${this.getDelivery(product.cfs)}"
                            date="${this.getDate(product.cfs.Product_Start_Date_C, product.cfs.Product_End_Date_C)}"
                            place="${this.getPlace(product.cfs)}"
                            isCourse="${this.productTypes.addExamBundled ?? false}"
                            >${btnText}</a>
                        </div>
                    </div>
                <div class="d-flex">
                    <div class="cart-full" class="mt-3" style="display:none;">
                        <div class="error_msg">Sorry, you can't add this item to the cart.</div>
                        <div class="error_msg">You have reached the maximum allowed quantity for purchase in your cart or the item isn't available anymore.</div>
                    </div>
                </div>
                `
            },

            getNonMemberPrice(product) {
                if (this.mainProductType == "courses" || this.mainProductType == "webinar" || this.mainProductType == "event") {

                    let price = 0;
                    price = this.nonMemberPriceList.find(e => e.product_id === product.id)?.price || 0;

                    if (!this.examProduct) {
                        return price.localizeCurrency();
                    }

                    let examPrice = 0;
                    examPrice = this.nonMemberPriceList.find(e => e.product_id === this.examProduct.id)?.price;

                    if (examPrice) {
                        let fullPrice = price ? price + examPrice : examPrice;

                        if (product.cfs.Delivery === 'CBT Exam') {
                            fullPrice = price;
                        }

                        return fullPrice.localizeCurrency();
                    }

                    return '';

                } else {

                    let price = 0;
                    let parentPrice = 0;

                    parentPrice = this.nonMemberPriceList.find(e => e.product_id === this.productData.id)?.price || 0;

                    if (!product) {
                        return parentPrice.localizeCurrency();
                    }

                    price = this.nonMemberPriceList.find(e => e.product_id === product.id)?.price || 0;

                    const fullPrice = price || 0;
                    return fullPrice.localizeCurrency();
                }
            },

            getMemberPrice(product) {
                if (this.mainProductType == "courses" || this.mainProductType == "webinar" || this.mainProductType == "event") {

                    let price = 0;
                    price = this.memberPriceList.find(e => e.product_id === product.id)?.price || 0;

                    if (!this.examProduct) {
                        return price.localizeCurrency();
                    }

                    let examPrice = 0;
                    examPrice = this.memberPriceList.find(e => e.product_id === this.examProduct.id)?.price;

                    if (examPrice) {
                        let fullPrice = price ? price + examPrice : examPrice;

                        if (product.cfs.Delivery === 'CBT Exam') {
                            fullPrice = price;
                        }

                        return fullPrice.localizeCurrency();
                    }

                    return '';

                } else {

                    let price = 0;
                    let parentPrice = 0;

                    parentPrice = this.memberPriceList.find(e => e.product_id === this.productData.id)?.price || 0;

                    if (!product) {
                        return parentPrice.localizeCurrency();
                    }

                    price = this.memberPriceList.find(e => e.product_id === product.id)?.price || 0;

                    const fullPrice = price || 0;
                    return fullPrice.localizeCurrency();
                }
            },

            async renderApp() {

                return new Promise((resolve) => {

                    this.getCategories().then(getCategoriesData => {

                        this.getProductInfoBC(this.mainProductId, true).then(async mainProductData => {

                            if (mainProductData != null && mainProductData.data != null) {
                                this.productData = mainProductData.data;

                                const parentCfs = {};
                                this.productData.custom_fields.forEach(field => {
                                    parentCfs[field.name] = field.value;
                                });

                                this.productData.cfs = parentCfs;

                                if (this.productData.order_quantity_maximum != 1) {
                                    this.showQuantity = true;
                                }

                                this.productTypes.isCourse = this.productData.categories.some(e => window.certificationIds.includes(e));

                                this.productTypes.addExamBundled = this.productData.categories.some(e => e === window.curriculumCategoryId);

                                if (mainProductData?.childProducts?.data) {
                                    mainProductData.childProducts.data.forEach(item => {

                                        const productCfs = {};

                                        item.custom_fields.forEach(field => {
                                            productCfs[field.name] = field.value;
                                        });

                                        item.cfs = productCfs;

                                        if (window.isCertificationCourse) {
                                            if (this.restrictTabs) {
                                                if (this.dummyTypesList.includes("Virtual Course")) {
                                                    this.productTypes.hasVirtualClassRoom = true;
                                                }

                                                if (this.dummyTypesList.includes("E-Learning")) {
                                                    this.productTypes.hasELearning = true;
                                                }

                                                if (this.dummyTypesList.includes("Exam")) {
                                                    this.productTypes.hasExam = true;
                                                }

                                                if (!this.restrictTabs || this.dummyTypesList.includes("Class")) {
                                                    this.productTypes.hasClassroom = true;
                                                }
                                            } else {
                                                this.productTypes.hasVirtualClassRoom = true;
                                                this.productTypes.hasELearning = true;
                                                this.productTypes.hasExam = true;
                                                this.productTypes.hasClassroom = true;
                                            }
                                        } else {
                                            if (item.cfs.Delivery === "Virtual Course" && (!this.restrictTabs || this.dummyTypesList.includes("Virtual Course"))) {
                                                this.productTypes.hasVirtualClassRoom = true;
                                            } else if (item.cfs.Delivery === "E-Learning" && (!this.restrictTabs || this.dummyTypesList.includes("E-Learning"))) {
                                                this.productTypes.hasELearning = true;
                                            } else if (item.cfs.Delivery === "CBT Exam" && (!this.restrictTabs || this.dummyTypesList.includes("Exam"))) {
                                                this.productTypes.hasExam = true;
                                            } else if (item.cfs.Delivery === "Class" && (!this.restrictTabs || this.dummyTypesList.includes("Class"))) {
                                                this.productTypes.hasClassroom = true;
                                            }
                                        }

                                        if (item.cfs.Delivery === "CBT Exam") {
                                            this.examProduct = item;
                                        }

                                        if (item.cfs.Delivery_Channel_C === "1350 - Other Self Study" && (!this.restrictTabs || this.dummyTypesList.includes("1350 - Other Self Study"))) {
                                            this.productTypes.hasPracticeExam = true;
                                        }

                                        this.childProducts.push(item);
                                    });

                                    if (!this.productTypes.hasVirtualClassRoom && !this.productTypes.hasELearning && !this.productTypes.hasExam && !this.productTypes.hasClassroom && !this.productTypes.hasPracticeExam) {
                                        this.productTypes.hasVirtualClassRoom = true;
                                    }
                                }

                                Promise.allSettled([this.priceListRequest(this.childProducts), this.nonMemberPriceListRequest(this.childProducts)]).then(priceLoaded => {
                                    this.renderProducts().then(isProductRendered => {
                                        resolve(true);
                                    });
                                });

                               

                                if (!this.includeExam) {
                                    this.examProduct = null;
                                } else if (this.inputExamProductId) {
                                    this.getProductInfoBC(this.inputExamProductId, false).then(examProductData => {
                                        this.examProduct = examProductData.data;
                                    });
                                }
                            }
                        });
                    });
                });
            },            

            async renderProducts(event) {

                if (event) {
                    this.filterPivot = event.target.dataset.filtertype;
                }

                return new Promise((resolve) => {

                    this.isLoading = true;
                    this.displayProducts = [];

                    if (this.mainProductType == "courses" || this.mainProductType == "webinar" || this.mainProductType == "event") {

                        if (this.productTypes.isCourse || this.productData.options?.length > 0) {
                            this.displayProducts = this.filterProducts();
                            this.setupFilters(this.displayProducts);
                        } else {
                            this.displayProducts.push(this.productData);
                        }

                        this.displayProducts = this.displayProducts.sort((a, b) => {
                            const aDate = this.getNADate(a.cfs.Product_Start_Date_C);
                            const bDate = this.getNADate(b.cfs.Product_Start_Date_C);
                            return aDate - bDate;
                        });

                        this.displayProducts.forEach((product, i) => {

                            //Set non-member and member price
                            if (i === 0) {
                                this.memberPriceHtml = this.getMemberPrice(product);
                                this.nonMemberPriceHtml = this.getNonMemberPrice(product);
                                this.buyButtonHtml = this.addToCartButton(product);
                            }

                            let website_url = product.cfs.Customer_contact_url_C.trim();

                            if (website_url) {
                                if (!isValidUrl(website_url)) {
                                    website_url = "https://" + website_url;
                                }
                            }
                        });

                    } else {

                        if (this.childProducts.length > 0) {
                            this.displayProducts = this.childProducts;
                        }
                        else {
                            this.displayProducts.push(this.productData);
                        }

                        //Sort to get hardcopy + download as first option on product page
                        this.displayProducts.sort((a, b) => (a.type > b.type) ? -1 : ((b.type > a.type) ? 1 : 0));

                        this.chosenProductId = this.chosenProductId ?? this.displayProducts[0].id;
                        this.chosenProduct = this.displayProducts.find(f => f.id == this.chosenProductId);

                        if (this.chosenProduct.cfs.Delivery === 'Book') {
                            if (this.chosenProduct.type === "physical") {
                                this.chosenProduct.delivery = this.displayProducts.length > 1 ? 'Hardcopy & Download' : 'Hardcopy only';
                            } else if (this.chosenProduct.type === "digital") {
                                this.chosenProduct.delivery = 'Download only';
                            }
                        } else {
                            this.chosenProduct.delivery = 'Download only';
                        }

                        this.chosenProduct.disabled = this.chosenProduct.inventory_tracking != "none" && this.chosenProduct.inventory_level <= 0;

                        this.memberPriceHtml = this.getMemberPrice(this.chosenProduct);
                        this.nonMemberPriceHtml = this.getNonMemberPrice(this.chosenProduct);
                    }

                    try {
                        if (this.displayProducts.length > 1 || (this.mainProductType == "courses" && this.bundleMainProductId != "")) {
                            Ecommerce.productList({
                                "id": this.productData.id,
                                "name": this.productData.name,
                                "sku": this.productData.sku,
                                "description": this.productData.description,
                                "price": this.productData.price,
                                "category": window.allCategories.filter(f => this.productData.categories.includes(f.id))[0].name,
                                "listName": this.productData.name,
                                "listId": this.mainProductId
                            });
                        }

                        this.displayProducts.forEach(item => {
                            if (item.is_visible && item.availability === "available") {
                                Ecommerce.productDetail({
                                    "id": item.id,
                                    "name": item.name,
                                    "sku": item.sku,
                                    "description": item.description,
                                    "price": item.price,
                                    "category": window.allCategories.filter(f => item.categories.includes(f.id))[0].name,
                                    "listName": this.productData.name,
                                    "listId": this.mainProductId
                                });
                            }
                        });

                    } catch (ex) {
                        debugger;
                    }

                    if (typeof (feather) !== "undefined") {
                        feather.replace();
                    }

                    this.isLoading = false;

                    resolve(true);
                });
            },

            filterProductsByKeyValue(products, key, value) {
                let productsTobeFiltered = this.childProducts;

                if (products && products != null && products.length > 0) {
                    productsTobeFiltered = products;
                }

                if (key && key != "") {

                    let filterOptions = {};
                    filterOptions[key] = value;

                    return productsTobeFiltered.filter(product => {
                        if (product.is_visible && product.availability === "available") {
                            return product.custom_fields.filter(custom_field => {
                                if (filterOptions[custom_field.name] !== undefined
                                    && filterOptions[custom_field.name] != null
                                    && custom_field.value.trim().length > 0
                                    && filterOptions[custom_field.name].length > 0
                                    && filterOptions[custom_field.name].includes(custom_field.value)) {
                                    return true;
                                }

                                if (custom_field.name === "Product_Start_Date_C") {
                                    const month = this.getFilterMonth(custom_field.value);

                                    if (filterOptions[custom_field.name] === month) {
                                        return true;
                                    }
                                }

                                return false;

                            }).length === Object.entries(filterOptions).filter(([key, value]) => value.length > 0).length;
                        }
                    });
                } else {
                    return productsTobeFiltered;
                }
            },

            filterProducts() {

                this.setupProductSupersetWithFilters();

                let filterOptions = {};
                filterOptions["Country_C"] = this.selectedCountry ?? "";
                filterOptions["Product_Start_Date_C"] = this.selectedMonth ?? "";
                filterOptions["State_C"] = this.selectedState ?? "";
                filterOptions["Delivery_Channel_C"] = this.selectedTab ?? "";

                this.filtersApplied = filterOptions.Country_C != "" || filterOptions.Product_Start_Date_C != "" || filterOptions.State_C != "";

                let currentTab = this.getTabName(filterOptions.Delivery_Channel_C);

                if (currentTab) {
                    let pageUrl = new URL(window.location);
                    pageUrl.searchParams.set('tab', currentTab);
                    pageUrl.searchParams.delete('id');
                    window.history.replaceState({}, '', pageUrl);
                }

                if (filterOptions.Country_C == ""
                    && filterOptions.Product_Start_Date_C == ""
                    && filterOptions.State_C == ""
                    && filterOptions.Delivery_Channel_C == "") {
                    return this.childProducts;
                }

                return this.childProducts.filter(product => {
                    if (product.is_visible && product.availability === "available") {
                        return product.custom_fields.filter(custom_field => {
                            if (filterOptions[custom_field.name] !== undefined
                                && filterOptions[custom_field.name] != null
                                && custom_field.value.trim().length > 0
                                && filterOptions[custom_field.name].length > 0
                                && filterOptions[custom_field.name].includes(custom_field.value)) {
                                return true;
                            }

                            if (custom_field.name === "Product_Start_Date_C") {
                                const month = this.getFilterMonth(custom_field.value);

                                if (filterOptions[custom_field.name] === month) {
                                    return true;
                                }
                            }

                            return false;

                        }).length === Object.entries(filterOptions).filter(([key, value]) => value.length > 0).length;
                    }
                });
            },

            changeQuantity(product, input) {

                if (input == "increase") {
                    if (product.order_quantity_maximum > 0) {
                        if (this.chosenProductQuantity < product.order_quantity_maximum) {
                            this.chosenProductQuantity++;
                        }
                    } else {
                        this.chosenProductQuantity++;
                    }
                } else {
                    if (this.chosenProductQuantity > 1) {
                        if (product.order_quantity_minimum > 0) {

                            if (this.chosenProductQuantity > product.order_quantity_minimum) {
                                this.chosenProductQuantity--;
                            }
                        } else {
                            this.chosenProductQuantity--;
                        }
                    }
                }
            },

            setFilterSetForProduct(product, isSuperSet) {
                product.custom_fields.forEach(field => {
                    if (field.name === "Product_Start_Date_C") {
                        const value = field.value.trim();

                        if (value.length > 0) {
                            let monthName = this.getFilterMonth(value);

                            if (isSuperSet) {
                                this.MonthsSuperset.push(monthName);
                            } else {
                                this.Months.push(monthName);
                            }
                        }
                    }

                    if (field.name === "Country_C") {
                        const value = field.value.trim();

                        if (value.length > 0) {
                            if (isSuperSet) {
                                this.CountriesSuperset.push(value);
                            } else {
                                this.Countries.push(value);
                            }
                        }
                    }

                    if (field.name === "State_C") {
                        const value = field.value.trim();

                        if (value.length > 0) {
                            if (isSuperSet) {
                                this.StatesSuperset.push(value);
                            } else {
                                this.States.push(value);
                            }
                        }
                    }
                });
            },

            getFilterValueByPivot() {
                if (this.filterPivot == "Product_Start_Date_C") {
                    return this.selectedMonth;
                }
                else if (this.filterPivot == "Country_C") {
                    return this.selectedCountry;
                }
                else if (this.filterPivot == "State_C") {
                    return this.selectedState;
                }
            },

            setupFilters(products) {

                this.Months = [];
                this.Countries = [];
                this.States = [];

                products.forEach(product => {
                    this.setFilterSetForProduct(product, false);
                });

                this.Months = sortArrayWithArray([...new Set(this.Months)], this.AllMonths);
                this.States = [...new Set(this.States)].sort();
                this.Countries = [...new Set(this.Countries)].sort();

                this.setupProductSupersetWithFilters();
            },

            setupProductSupersetWithFilters() {
                let productSuperset = this.filterProductsByKeyValue(null, "Delivery_Channel_C", this.selectedTab);

                this.MonthsSuperset = [];
                this.StatesSuperset = [];
                this.CountriesSuperset = [];

                productSuperset.forEach(product => { this.setFilterSetForProduct(product, true); });

                this.MonthsSuperset = sortArrayWithArray([...new Set(this.MonthsSuperset)], this.AllMonths).filter(f => f != undefined && f.trim() != "");
                this.StatesSuperset = [...new Set(this.StatesSuperset)].sort().filter(f => f != undefined && f.trim() != "");
                this.CountriesSuperset = [...new Set(this.CountriesSuperset)].sort().filter(f => f != undefined && f.trim() != "");

                this.filtersAvailable = this.MonthsSuperset.length != 0 || this.StatesSuperset.length != 0 || this.CountriesSuperset.length != 0;
            },

            clearFilters() {
                this.selectedCountry = "";
                this.selectedMonth = "";
                this.selectedState = "";
            },

            resetFilters() {
                this.clearFilters();

                this.renderProducts();
            },

            copyLinkToClipboard(event) {

                var $temp = $("<input>");
                $("body").append($temp);

                $temp.val(event.currentTarget.attributes['data-link'].value).select();

                document.execCommand("copy");

                $temp.remove();

                $(event.currentTarget.parentElement).prepend("<div class='copy-success hide'>Link copied to clipboard.</div>");
                $(event.currentTarget.parentElement).find(".copy-success").fadeIn(500);

                window.setTimeout(function (event) {
                    $(event.target).parent().find(".copy-success").fadeOut(500, "swing", function () {
                        $(this).remove();
                    });
                }, 4000, event);
            },

            changeDonationAmount(event) {
                let quantity = event.target.attributes['data-predefined-denomination']?.value;

                if (quantity) {
                    this.chosenProductQuantity = Number(quantity);
                } else if (event.target.value && event.target.value != "0") {
                    this.chosenProductQuantity = Number(event.target.value.replace(/[^0-9\.]+/g, ""));
                }

                $(".predefinedDenomination").removeClass("selected");
                event.target.classList.add("selected");


                $(".predefinedDenomination[data-predefined-denomination="
                    + (this.chosenProductQuantity != "" ? this.chosenProductQuantity : 0) + "]").addClass("selected");

            },

            changeUpsellQuantity(event) {

                let upsellProductId = event.target.attributes['data-productId']?.value;
                let quantity = event.target.value;

                //Have this if condition separate as we don't want to write code for show/hide twice.
                if (quantity == 0) {
                    quantity = 1;
                }

                if (quantity == "10+") {
                    quantity = 10;
                    $("#upsellCollectionProduct_" + upsellProductId).find('.quantitySelect').hide();
                    $("#upsellCollectionProduct_" + upsellProductId).find('.quantityTextbox').show();
                } else if (quantity < 10) {
                    $("#upsellCollectionProduct_" + upsellProductId).find('.quantitySelect').show();
                    $("#upsellCollectionProduct_" + upsellProductId).find('.quantityTextbox').hide();
                }

                $("#upsellCollectionProduct_" + upsellProductId).find('.quantitySelect')[0].value = quantity;
                $("#upsellCollectionProduct_" + upsellProductId).find('.quantityTextbox')[0].value = quantity;
                $("#upsellCollectionProduct_" + upsellProductId).find(".add-to-cart")[0].setAttribute("quantity", quantity);
            }
        },

        computed: {

        },

        watch: {

        },

        created() {
            if (typeof (window.scrollToElement) != "undefined" && window.scrollToElement != "") {
                scrollOnPagination(window.scrollToElement);
            }

            this.renderApp().then(isAppRendered => {

                this.selectedTab = this.getInitialTab();

                if (typeof (feather) !== "undefined") {
                    feather.replace();
                }

                if (typeof (window.selectedCourseTab) != "undefined" && window.selectedCourseTab != "") {
                    if (this.isTabAvailable(window.selectedCourseTab)) {
                        this.selectedTab = this.getBCTabName(window.selectedCourseTab);
                    }
                }

                this.renderProducts();

                window.setTimeout(function () {
                    if (typeof (window.isProductHighlighted) != "undefined"
                        && !window.isProductHighlighted
                        && $("#product-" + selectedProductId).length > 0) {
                        scrollOnPagination("product-" + window.selectedProductId);
                        $("#product-" + window.selectedProductId).addClass("highlight-course");
                        window.isProductHighlighted = true;
                    }
                }, 500);

                if (window.redirectToCheckout && window.isDonationProduct && this.isUserLoggedIn) {
                    let addToCartButtonAttributes = $("#" + this.domId + "_" + this.mainProductId).find(".add-to-cart")[0].attributes;

                    addToCartMountedApp['id_' + this.mainProductId].openAddtoCartPopup(addToCartButtonAttributes, this.mainProductId).then(currentCartId => {
                        this.setCookieandRedirectToCartForDonationProduct(currentCartId);
                    });
                }
            });
        },

        mounted() {

        },

        filters: {
        }
    });

    mainProductIds.push(item.productId);

    productsMountedApp['id_' + item.productId] = productsApp.mount('#' + item.id + '_' + item.productId);

    let dynamicAddToCartId = 'dynamicAddToCart_' + item.productId;

    $('#dynamicAddToCart').clone().prop({ 'id': dynamicAddToCartId }).insertAfter('#dynamicAddToCart');

    addToCartMountedApp['id_' + item.productId] = addToCartApp(item).mount('#' + dynamicAddToCartId);
}

function upsellProductContainersSetup(item) {

    let upsellProductContainerApp = Vue.createApp({
        data() {
            return {
                bundleMainProductId: item.bundleMainProductId,
                bundleMainElementId: item.bundleMainElementId,
                bundleAloneButtonText: "",
                mainAndBundleButtonText: "",
                currentSelectedProducts: item.currentSelectedProducts,
                bundlesCount: item.currentSelectedProducts.length,
                mainAndBundlesCount: item.currentSelectedProducts.length + 1,
                isAddonsSoldSeparately: item.isAddonsSoldSeparately,
                showButtonMessage: false
            }
        },

        methods: {
            changeBundle(upsellProductId, isSelected) {
                this.currentSelectedProducts = this.currentSelectedProducts.filter(i => i != upsellProductId);

                if (isSelected) {
                    this.currentSelectedProducts.push(upsellProductId);
                }

                this.bundlesCount = this.currentSelectedProducts.length;
                this.mainAndBundlesCount = this.bundlesCount + 1;

                if (this.bundlesCount > 1) {
                    this.bundleAloneButtonText = "Add selected items to Cart";
                } else {
                    this.bundleAloneButtonText = "Add selected item to Cart";
                }

                if (this.mainAndBundlesCount > 2) {
                    this.mainAndBundleButtonText = "Add selected items to Cart";
                } else {
                    this.mainAndBundleButtonText = "Add selected item to Cart";
                }

                if (this.isAddonsSoldSeparately && this.bundlesCount == 0) {
                    this.showButtonMessage = true;
                } else if (this.mainAndBundlesCount <= 1) {
                    this.showButtonMessage = true;
                } else {
                    this.showButtonMessage = false;
                }
            },

            async addMainToCart(event) {
                let mainAppButtonAttributes = $("#" + this.bundleMainElementId + "_" + this.bundleMainProductId).find(".add-to-cart")[0].attributes;

                await addToCartMountedApp['id_' + this.bundleMainProductId].openAddtoCartPopup(mainAppButtonAttributes, this.bundleMainProductId);

                productsAddedToCart = [];
            },

            async addBundleAloneToCart(event) {
                let promisesToResolve = [];

                var firstAddonProductId = this.currentSelectedProducts[0];

                await addToCartMountedApp['id_' + firstAddonProductId].openAddtoCartPopup($("#upsellCollectionProduct_" + firstAddonProductId).find(".add-to-cart")[0].attributes, firstAddonProductId);

                if (this.currentSelectedProducts.length > 1) {
                    let restOfTheAddons = this.currentSelectedProducts.filter(i => i != firstAddonProductId);

                    restOfTheAddons.forEach(async function (id) {
                        promisesToResolve.push(addToCartMountedApp['id_' + id].openAddtoCartPopup($("#upsellCollectionProduct_" + id).find(".add-to-cart")[0].attributes, id));
                    });

                }

                Promise.all(promisesToResolve).then(async (resolvedData) => {
                    await addToCartCommonApp.showAddToCart(true);
                });

            },

            async addMainAndBundlesToCart(event) {

                let promisesToResolve = [];
                let mainAppButtonAttributes = $("#" + this.bundleMainElementId + "_" + this.bundleMainProductId).find(".add-to-cart")[0].attributes;

                const hidePopupNode = document.createAttribute("data-hidePopup");
                hidePopupNode.value = "true";
                mainAppButtonAttributes.setNamedItem(hidePopupNode);

                await addToCartMountedApp['id_' + this.bundleMainProductId].openAddtoCartPopup(mainAppButtonAttributes, this.bundleMainProductId);

                this.currentSelectedProducts.forEach(async function (id) {
                    promisesToResolve.push(addToCartMountedApp['id_' + id].openAddtoCartPopup($("#upsellCollectionProduct_" + id).find(".add-to-cart")[0].attributes, id));
                });

                Promise.all(promisesToResolve).then(async (resolvedData) => {
                    await addToCartCommonApp.showAddToCart(true);
                });

                mainAppButtonAttributes.removeNamedItem("data-hidePopup");
            }
        },

        watch: {
            currentSelectedProducts: {
                handler(newValue, oldValue) {

                },
                immediate: true
            }
        },

        created() {
            this.bundleAloneButtonText = "";
            this.mainAndBundleButtonText = "";
        }
    });

    upsellProductContainersMountedApp['id_' + item.bundleMainProductId] = upsellProductContainerApp.mount('#' + item.id + '_' + item.bundleMainProductId);
}


function registerWebinar(productId) {
    $('.loading-overflow-add-cart').show();

    $.ajax({
        url: "/webinar/registration",
        method: 'POST',
        headers: {
            RequestVerificationToken:
                $('input:hidden[name="__RequestVerificationToken"]').val()
        },
        data: {
            webinarId: productId
        },
        success: function (result) {
            if (result.status) {
                onAfterWebinarRegistration(true, result.type.toLowerCase() == "on demand");
            } else {
                onAfterWebinarRegistration(false, false);
            }
        },
        error: (data) => {
            onAfterWebinarRegistration(false, false);
        }
    })
}

function onAfterWebinarRegistration(isSuccess, isOndemand) {
    $('.loading-overflow-add-cart').hide();
    var redirectLink = "/learn#webinars";

    try {
        if (document.referrer != "" && document.referrer != undefined && window.location.host == (new URL(document.referrer)).host) {
            redirectLink = document.referrer;
        }
    }
    catch {

    }


    if (isSuccess) {
        $("#webinar-registration-modal .modal-title").text("Webinar Registration Successful!");
        if (isOndemand) {
            $("#webinar-registration-modal .reg-message").html("Thank you for registering for the On-demand Webinar. You can access the webinar from your <a href='/profile'>Profile Page</a>.");
        } else {
            $("#webinar-registration-modal .reg-message").text("Thank you for registering for our upcoming webinar! We're excited to have you join us. Details and access information will be sent to your email shortly. Get ready for an insightful session!")
        }

        $("#webinar-registration-modal .close-btn button, #webinar-registration-modal .btn-close-popup").attr("data-href", redirectLink);
    } else {
        $("#webinar-registration-modal .modal-title").text("Unable to Complete Your Webinar Registration");
        $("#webinar-registration-modal .reg-message").text("Something went wrong with your registration. Please try again later. Apologies for any inconvenience. Thank you for your understanding.")
        $("#webinar-registration-modal .close-btn a").attr("data-bs-dismiss", "modal");
    }

    var webinarModal = new bootstrap.Modal(document.getElementById('webinar-registration-modal'))
    webinarModal.show();
}

function onCloseWebinarRegPopup(event) {
    var webinarModal = bootstrap.Modal.getInstance(document.getElementById('webinar-registration-modal'));
    webinarModal.hide();

    if (event.currentTarget.dataset.href) {
        $('.loading-overflow-add-cart').show();
        window.location.href = event.currentTarget.dataset.href;
    }
}