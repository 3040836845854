var isMarketoPopupTriggered = false;
var urlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');
var isFirstBrowserScroll = true;
var makeFooterSubscriptionStatic = true;
var isVideoPopupOpened = false;

const TimeZoneMap = {
    "IST": "Asia/Calcutta",
    "EST": "America/New_York",
    "ET": "America/New_York",
    "MST": "America/Boise",
    "UTC": "Etc/UTC",
};

const WaWApp = {
    IDP: 0,
    WWW: 1,
    INDIA: 2,
    MENA: 3,
    All: 4
}

$(document).ready(function () {
    //this is for fixed menu and remove scroll bar tablet and mobile devices

    $('button.main-nav-bar-toggler').click(function () {
        mobileMenuToggler();
    });

    $(".navbar-collapse #menu .nav-item .nav-link").click(function () {
        if (!$(".navbar-collapse").hasClass("menuHideAndDisplay")) {
            $('.navbar-collapse').collapse('hide');
            mobileMenuToggler();
        }
    });

    function mobileMenuToggler() {
        $('#navbarNav').toggleClass('menuHideAndDisplay');
        $('.header-container').toggleClass('menuFixed');
        if ($('.header-container').hasClass('menuFixed')) {
            $('.header-container').css("z-index", getMaxZIndex() + 1);
        } else {
            $('.header-container').css("z-index", 1);
        }
        $('body').toggleClass('ScrollOff');
    }

    //this is for fixed menu and remove scroll bar tablet and mobile devices

    //this is for the sticky header while scrolling
    var scrollClassAdd = document.getElementById("scroll-nav-add-rem");
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            if (scrollClassAdd) {
                scrollClassAdd.classList.add("scroll-nav");
            }
            if (typeof (isBannerEnabled) != "undefined" && isBannerEnabled) {
                hideBanner();
            }
        }
        else {
            if (scrollClassAdd) {
                scrollClassAdd.classList.remove("scroll-nav");
            }
            if (typeof (isBannerEnabled) != "undefined" && isBannerEnabled) {
                showBanner();
            }
        }
        menuSelectionOnScroll();
    });

    //Scroll to top button

    var scrollToTopBtn = document.querySelector(".scrollToTopBtn");
    var footerSubSection = document.querySelector("#footerSub");
    var rootElement = document.documentElement;

    function handleScroll() {
        // Do something on scroll
        var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
        if (rootElement.scrollTop / scrollTotal > 0.4) {
            // Show button
            scrollToTopBtn.classList.add("showscrollToTopBtn");
        } else {
            // Hide button
            scrollToTopBtn.classList.remove("showscrollToTopBtn");
        }

        if (footerSubSection != null) {
            if (rootElement.scrollTop / scrollTotal > 0.2 && !window.wawUser.hasWorkspanWeeklySubscription && !makeFooterSubscriptionStatic) {
                footerSubSection.classList.add("sticky-bottom");
            } else {
                footerSubSection.classList.remove("sticky-bottom");
            }
        }
    }

    function scrollToTop() {
        // Scroll to top logic
        rootElement.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    scrollToTopBtn.addEventListener("click", scrollToTop);
    document.addEventListener("scroll", handleScroll);

    //Scroll to top button

    //Menu - Activate on scroll

    var lastId,
        topMenu = $("#scroll-nav-add-rem"),
        topMenuHeight = topMenu.outerHeight() + 10,
        menuItems = topMenu.find(".customMenu a"),
        menuItemsArray = [...menuItems].map(function (e) { return $(e).attr("href") }).filter(x => x.startsWith("#")),
        widgetIdsArray = [...$(".renderWidgetBlock")].map(function (e) { return '#' + $(e).attr("id") });
    scrollItems = menuItemsArray.sort(function (a, b) {
        return widgetIdsArray.indexOf(a) - widgetIdsArray.indexOf(b);
    });

    function menuSelectionOnScroll() {
        if (scrollItems.length > 0) {
            var fromTop = $(this).scrollTop() + topMenuHeight;
            var cur = scrollItems.map(function (e) {
                if ($(e)?.offset()?.top < fromTop)
                    return e;
            }).filter(x => x != undefined);
            var id = cur[cur.length - 1];
            if (lastId !== id) {
                lastId = id;
                menuItems
                    .removeClass("active")
                    .filter("[href='" + id + "']").addClass("active");
            }
        }
    }

    //Menu - Activate on scroll

    //Update all anchor tags with target=_blank whose href points to a different domain than the current domain
    var currentDomain = location.host;
    currentDomain = new RegExp(currentDomain, "i");

    var anchorTags = document.getElementsByTagName('a');
    for (var i = 0; i < anchorTags.length; i++) {
        try {
            var host = anchorTags[i].host;
            var href = anchorTags[i].href;

            var possibleExternalHrefs = ["/lms-sso", "/engage-sso", "/career-sso", "/psi-sso", "/canvas-sso", "/squirrel-sso", "/wagescape-sso"];
            var possibleStartWithExternalHrefs = ["/foundation/scholarship-application/"];

            var globalUrlToReplace = "%7Burl.global%7D";
            var indiaUrlToReplace = "%7Burl.india%7D";
            var menaUrlToReplace = "%7Burl.mena%7D";
            var memberAccessUrlToReplace = "%7Burl.member%7D";

            if (!isNullOrWhitespace(href) && new URL(href).origin != AppConfig_AppPaths_IDPURL.value && href.indexOf("javascript:void(0)") < 0) {
                if (!currentDomain.test(host) || possibleExternalHrefs.includes(new URL(href).pathname)
                    || possibleStartWithExternalHrefs.some(function (link) { return new URL(href).pathname.startsWith(link) })
                    || isFile(href) || host.indexOf("." + location.host) > -1) {
                    anchorTags[i].setAttribute('target', '_blank');
                    anchorTags[i].innerHTML += "<span class='sr-only' aria-hidden='true'>Open in a new tab</span>";

                    let checkEmail = anchorTags[i].href.indexOf("mailto:");
                    if (checkEmail > -1) {
                        anchorTags[i].innerHTML += "<span class='sr-only' aria-hidden='true'>Email</span>";
                    }
                }

                if (href.includes(globalUrlToReplace)) {
                    var urlToSet = anchorTags[i].pathname.substring(anchorTags[i].pathname.indexOf("%7B"), anchorTags[i].pathname.length);
                    href = urlToSet.replace(globalUrlToReplace, AppConfig_AppPaths_WebURL.value);
                    anchorTags[i].setAttribute("href", href + anchorTags[i].search + anchorTags[i].hash);
                    anchorTags[i].setAttribute('target', '_blank');
                }

                if (href.includes(indiaUrlToReplace)) {
                    var urlToSet = anchorTags[i].pathname.substring(anchorTags[i].pathname.indexOf("%7B"), anchorTags[i].pathname.length);
                    href = urlToSet.replace(indiaUrlToReplace, AppConfig_AppPaths_INDIAURL.value);
                    anchorTags[i].setAttribute("href", href + anchorTags[i].search + anchorTags[i].hash);
                    anchorTags[i].setAttribute('target', '_blank');
                }

                if (href.includes(menaUrlToReplace)) {
                    var urlToSet = anchorTags[i].pathname.substring(anchorTags[i].pathname.indexOf("%7B"), anchorTags[i].pathname.length);
                    href = urlToSet.replace(menaUrlToReplace, AppConfig_AppPaths_MENAURL.value);
                    anchorTags[i].setAttribute("href", href + anchorTags[i].search + anchorTags[i].hash);
                    anchorTags[i].setAttribute('target', '_blank');
                }

                if (anchorTags[i].textContent === "Learn More") {
                    anchorTags[i].innerHTML += "<span class='sr-only' aria-hidden='true'>Learn More</span>";
                }

                if (href.includes(memberAccessUrlToReplace)) {

                    var urlToSet = anchorTags[i].pathname.substring(anchorTags[i].pathname.indexOf("%7B"), anchorTags[i].pathname.length);

                    var memberUrl = urlToSet.replace(memberAccessUrlToReplace, "");

                    if (window.wawUser.id != null && window.wawUser.isMember) {

                        anchorTags[i].setAttribute("href", memberUrl);

                        if (!memberUrl.startsWith("/") && !currentDomain.test(memberUrl)) {
                            anchorTags[i].setAttribute('target', '_blank');
                        }

                    } else {
                        anchorTags[i].setAttribute("href", "#memberOnlyAccessDenied");
                        anchorTags[i].setAttribute("data-href", memberUrl);
                        anchorTags[i].setAttribute('target', '');
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    //Update all anchor tags with target=_blank whose href points to a different domain than the current domain

    // Scroll to ID

    $("a[href^='#']").on("click", function (event) {

        var doScroll = $(this).attr("href") != "#";
        if ($(this).attr("href") == "#main" || $(this).attr("href").startsWith("#mktoForm")) {
            return;
        }

        if ($(this).attr("href") == "#memberOnlyAccessDenied") {
            openMemberOnlyAccessDenied(event);
        }
        else if ($(this).attr("href") == "#print_page") {
            window.print();
        }
        else if (doScroll) {

            var position = $($(this).attr("href")).offset().top;

            var headerOffset = window.outerWidth < 992 ? 100 : 80;
            position -= headerOffset;

            rootElement.scrollTo({
                top: position,
                behavior: "smooth"
            });
        }

        event.preventDefault();
    });

    // Scroll to ID

    //Add border-bottom for the active menu
    var pathname = "/" + window.location.pathname.split("/")[1];
    $("ul.navbar-nav > li.nav-item > a.nav-link[href='" + pathname + "']").addClass("active");
    //Add border-bottom for the active menu

    //Search box

    $(".waw_searchBox input").keydown(function (event) {
        if (event.which === 13) {
            event.preventDefault();

            var keyword = $(this).val();
            var url = window.location.origin;

            if (keyword.trim() != "") {
                url += "/search/Init?q=" + keyword;
            }

            window.location.replace(url);
        }
    });

    //Search box

    //slick slider script

    if ($('.subNavGradientBlockMultipleItems').length > 0) {

        $('.subNavGradientBlockMultipleItems').slick({
            infinite: false,
            dots: false,
            accessibility: true,
            slidesToShow: 7,
            slidesToScroll: 1,
            prevArrow: '<div class="slick-prev slick-arrow" role="button" tabindex="0"><img loading="lazy" src="/img/icons/arrow-left.svg" /><span class="sr-only" aria-hidden="true">Previous</span></div>',
            nextArrow: '<div class="slick-next slick-arrow" role="button" tabindex="0"><img loading="lazy" src="/img/icons/arrow-right.svg" /><span class="sr-only" aria-hidden="true">Next</span></div>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                        dots: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        dots: false
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                }
            ]
        });

        setTimeout(function () {
            var subNavSlick = $('.subNavGradientBlockMultipleItems').slick('getSlick');
            var subNavMenus = subNavSlick.$slides;

            subNavMenus.toArray().forEach(function (menu) {
                if (menu.classList.contains("active")) {
                    subNavSlick.slickGoTo(menu.dataset.slickIndex);
                }
            });
        }, 1000);

        var arrowSelector = $('.subNavGradientBlockMultipleItems > .slick-arrow');
        arrowSelector.off("click");
    }

    //For Conference Sub menu
    if ($('.SubMenu-Area').length > 0) {
        $('.SubMenu-Area').slick({
            infinite: false,
            dots: false,
            accessibility: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            prevArrow: '<div class="slick-prev slick-arrow" role="button" tabindex="0"><img loading="lazy" src="/img/icons/arrow-left.svg" /><span class="sr-only" aria-hidden="true">Previous</span></div>',
            nextArrow: '<div class="slick-next slick-arrow" role="button" tabindex="0"><img loading="lazy" src="/img/icons/arrow-right.svg" /><span class="sr-only" aria-hidden="true">Next</span></div>',
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: false,
                        dots: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: false,
                    }
                }
            ]
        });

        setTimeout(function () {
            var subNavSlick = $('.SubMenu-Area').slick('getSlick');
            var subNavMenus = subNavSlick.$slides;

            subNavMenus.toArray().forEach(function (menu) {
                if (menu.children[0].classList.contains("active")) {
                    subNavSlick.slickGoTo(menu.dataset.slickIndex);
                }
            });
        }, 1000);

        var arrowSelector = $('.SubMenu-Area > .slick-arrow');
        arrowSelector.off("click");
    }
    //slick slider script

    var retryCounter = 0;

    function checkAndLoadGA() {
        if ((typeof ga === "function" && ga.loaded) || retryCounter++ > 20) {
            Ecommerce.init();
        } else {
            setTimeout(checkAndLoadGA, 500);
        }
    }

    //Update all img tags with alt and title

    function updateImageAltTitle() {
        var imgTags = document.getElementsByTagName('img');

        for (let i = 0; i < imgTags.length; i++) {
            try {
                //We can set the alt text as Image name if needed in future.
                //var imgName = imgTags[i].src.replace(/^.*[\\\/]/, "");

                //imgName = imgName.replace(/(.*)\.(.*?)$/, "$1")
                //    .replaceAll("%20", " ")
                //    .replace(/\d{1,5}x\d{1,5}/, "")
                //    .replace(/_/g, " ")
                //    .replaceAll(/-/g, " ")
                //    .replaceAll("  ", "");

                //imgName = imgName.trim();

                if (!imgTags[i].alt) {
                    var pageTitle = $("title").text();
                    imgTags[i].alt = pageTitle;
                }
            } catch (ex) {

            }
        }
    }

    //Update all img tags with alt and title

    function setLastVisitedPage() {
        var lastVisitedPage = localStorage.getItem("lastVisitedPage");
        var currentPage = location.href;

        if (location.pathname == '/membership/volunteer/volunteer-application') {
            if (!localStorage.getItem("lastVisitedVolunteerPage")) {
                localStorage.setItem("lastVisitedVolunteerPage", lastVisitedPage);
            }
            else {
                lastVisitedPage = localStorage.getItem("lastVisitedVolunteerPage");
            }
        }
        else {
            localStorage.removeItem("lastVisitedVolunteerPage")
        }

        if (lastVisitedPage && currentPage && lastVisitedPage != currentPage) {
            $(".header_close").attr("href", lastVisitedPage);
        }

        localStorage.setItem("lastVisitedPage", currentPage);
    }

    //check for video links for modal addition
    var maxVideoHeight = 896;
    $(window).resize(function () {
        var videoHeight = window.innerHeight - 49;
        if (videoHeight < maxVideoHeight) {
            $('#videoEmbed').css('height', videoHeight);
        }
        else {
            $('#videoEmbed').css('height', maxVideoHeight);
        }

        var popId = 0;
        $('a').each(function () {
            var value = $(this).attr('href');
            if (value != undefined) {
                if (value.startsWith('_video_')) {
                    if ($(this).html().toLowerCase().includes('<img')) {
                        var imgHeight = $(this).children().innerHeight();
                        popId = popId + 1;
                        $('#popVideoID' + popId).css({
                            height: imgHeight
                        });
                    }
                }
            }
        });

    });

    //Append permalinks to each renderWidgetBlock
    $(".renderWidgetBlock").each(function () {

        var id = $(this).attr("id");

        if (id) { //make sure the element has an id
            $(this).append($("<a/>", {
                href: "#" + $(this).attr("id"),
                text: "¶",
                class: "widget-permalink"
            }));
        }
    });

    $(document).on("click", ".permalink", function () {
        copyPermalinkToClipboard($(this));
    });

    function copyPermalinkToClipboard(element) {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(window.location.origin + window.location.pathname + window.location.search + element.attr("href")).select();
        document.execCommand("copy");
        $temp.remove();

        showToast("Link copied to clipboard.", 4000, "success");
    }

    //Show tooltip for clamped contents

    $(".waw-show-tooltip").on("mouseover", (function () {

        $(this).removeAttr("title");

        if (this.offsetWidth < this.scrollWidth || this.offsetHeight < this.scrollHeight) {
            $(this).tooltip({
                title: $(this).text(),
                placement: "right",
                delay: { show: 1000, hide: 100 },
                html: true,
                template: "<div class='tooltip' role='tooltip'><div class='tooltip-arrow'></div><div class='tooltip-inner'>" + $(this).text() + "</div></div>"
            });
        }
    }));

    //Show tooltip for clamped contents

    //Open member landing page

    function openMemberLandingPage() {
        var query = new URLSearchParams(window.location.search);
        var memberLandingPage = query.get("returnToMemberPage");

        if (memberLandingPage) {
            var url = memberLandingPage;

            if (urlRegex.test(memberLandingPage)) {
                let domainThirdParty = (new URL(memberLandingPage));
                let domainSelf = (new URL(window.location.href));
                if (domainThirdParty == domainSelf) {
                    url = window.location.origin + url;
                }
            }
            else if (isFile(memberLandingPage)) {
                url = window.location.origin + url;
            }

            if (isMember && isMember.toLocaleString() == "true") {
                window.open(url, '_blank').focus();
                window.location = window.location.pathname;
            } else {
                openMemberOnlyAccessDenied(null, memberLandingPage);
            }
        }
    }

    //Open member landing page

    if (typeof (isBannerEnabled) != "undefined" && isBannerEnabled) {
        showBanner();
    } else {
        $("#banner-notification").hide();
    }

    //Update all <a> tags with role equals to button

    function UpdateButtonsWithRoleAttribute() {
        var aTags = document.getElementsByTagName('a');

        for (let i = 0; i < aTags.length; i++) {

            let checkUrl = aTags[i].href.indexOf("#");
            // Only set attribute below for links that have no role attribute and the href is not #
            if ((!aTags[i].attributes.role || !aTags[i].attributes.role.value) && checkUrl === -1) {
                aTags[i].setAttribute("role", "button");
                aTags[i].setAttribute("tabindex", "0");
            }

            // Set attribute only for href that started with # like Marketo form
            if (checkUrl > -1) {
                aTags[i].setAttribute("role", "button");
            }
        }
    }

    //Update all <a> tags with role equals to button

    updateCartLinkInMenu();
    updateImageAltTitle();
    checkAndLoadGA();
    setMaxZindexForModal();
    setLastVisitedPage();
    MktoForms2.whenReady(onMarketoFormReady);
    openMemberLandingPage();
    triggerURLScroll();
    UpdateButtonsWithRoleAttribute();

    makeFooterSubscriptionStatic = getCookie('makeFooterSubscriptionStatic');

    if (!checkFlexGap()) {
        $("body").addClass("no-flexbox-gap");
    }

    setTimeout(function () {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        popoverTriggerList.map(function (popoverTriggerEl) {
            if (popoverTriggerEl.attributes["data-bs-content"] != undefined) {
                return new bootstrap.Popover(popoverTriggerEl);
            }
        });
    }, 1000);
});

window.onload = function () {
    var currentQuery = new URLSearchParams(window.location.search);
    var vdCategoryFromUrl = currentQuery.get("vdcategory");

    if (vdCategoryFromUrl) {
        setTimeout(
            window.scrollTo({
                top: $("#vendorList").position().top - 200,
                behavior: "smooth"
            }), 3000);
    }

    setTimeout(loadVideoPlayButtons, 1000);
}

function hideBanner() {
    $("#banner-notification").hide();
    $("#scroll-nav-add-rem").css("top", "0px");
    $("header + div").css("margin-top", "");
}

function showBanner() {
    $("#banner-notification").show();
    if ($("#scroll-nav-add-rem").is(":visible") && $("#banner-notification").is(":visible")) {
        $("#scroll-nav-add-rem").css("top", $("#banner-notification").outerHeight());
        $("header + div").css("margin-top", $("#scroll-nav-add-rem").outerHeight() + $("#banner-notification").outerHeight());
    }
}

function isFile(url) {
    var urlPath = url;
    if (urlRegex.test(url)) {
        urlPath = new URL(url).pathname;
    }

    if (urlPath) {
        return urlPath.split("/").pop().indexOf(".") > 0;
    }

    return false;
}

function getOrchardImageMediaText(cmsImage) {
    if (cmsImage != null && cmsImage.MediaTexts != null && cmsImage.MediaTexts.Count != 0) {
        return cmsImage.MediaTexts[0];
    }

    return "";
}

function openMemberOnlyAccessDenied(event, url) {

    var returnUrl = "";

    if (event) {
        returnUrl = $(event.target).attr("data-href");
    } else {
        returnUrl = url;
    }

    var setLandingPage = false;
    var href = "/wawlogin?returnTo=" + encodeURIComponent(returnUrl);

    if (urlRegex.test(returnUrl)) {
        let domainThirdParty = (new URL(returnUrl));
        let domainSelf = (new URL(window.location.href));
        if (domainThirdParty != domainSelf) {
            setLandingPage = true;
        }
    }
    else if (isFile(returnUrl)) {
        setLandingPage = true;
    }

    if (setLandingPage) {
        var pageUrl = window.location.pathname + window.location.search;
        href = "/wawlogin?returnTo=" + encodeURIComponent(pageUrl);// + "%3FreturnToMemberPage%3D" + returnUrl;
        //uncomment the above and users will return to the same page with a landing page in query string which will be opened in a new tab.
        //But browsers will give security issues for opening a popup.
    }

    $(".memberonlyAccessDeniedLoginButton").attr("href", href);

    $("#MemberOnlyAccessDenied").modal("show");

    if (event) {
        event.preventDefault();
    }
}

function openLoginAccessDenied(event, url, showCreateAccount) {

    var returnUrl = "";
    var isDonationProduct = false;
    var quantity = 0;
    var setLandingPage = false;

    if (event) {
        returnUrl = $(event.target).attr("data-href");
        isDonationProduct = $(event.target).attr("data-isDonationProduct");
        quantity = $(event.target).attr("quantity");
    } else {
        returnUrl = url;
    }

    var href = "/wawlogin?returnTo=" + encodeURIComponent(returnUrl);

    if (urlRegex.test(returnUrl)) {
        let domainThirdParty = (new URL(returnUrl));
        let domainSelf = (new URL(window.location.href));
        if (domainThirdParty != domainSelf) {
            setLandingPage = true;
        }
    }
    else if (isFile(returnUrl)) {
        setLandingPage = true;
    }

    if (setLandingPage) {
        var pageUrl = window.location.pathname + window.location.search;
        href = "/wawlogin?returnTo=" + encodeURIComponent(pageUrl);// + "%3FreturnToMemberPage%3D" + returnUrl;
        //uncomment the above and users will return to the same page with a landing page in query string which will be opened in a new tab.
        //But browsers will give security issues for opening a popup.
    }

    if (isDonationProduct) {
        $(".loginAccessDeniedLoginButton").attr("href", href + ((decodeURIComponent(href).match(/\?/g) || []).length > 1 ? "%26quantity%3D" : "%3Fquantity%3D") + quantity);
        $("#loginAccessDeniedDesc").html("Please log into your account to continue making your donation.");
    } else {
        $(".loginAccessDeniedLoginButton").attr("href", href);
        $("#loginAccessDeniedDesc").html("Please log into your account now to access this content.");
    }

    if (showCreateAccount) {
        var accountRegUrl = AppConfig_AppPaths_IDPURL.value + "/auth/register?ReturnURL=" + encodeURIComponent(url);
        $(".create-acnt-link").show();
        $(".create-acnt-link a").attr("href", accountRegUrl)
    }

    $("#loginAccessDenied").modal("show");

    if (event) {
        event.preventDefault();
    }
}

//Get Max Z-Index

function getMaxZIndex() {
    return Math.max.apply(null,
        $.map($('body *'), function (e, n) {
            if ($(e).css('position') != 'static') {
                let maxZIndex = parseInt($(e).css('z-index'));
                //Due to some browser extension the z-index has been set to high. To avoid this kind of issue we are returning 2147483600 if we get max z index morethan 2147483600.
                if (maxZIndex > 2147483600) {
                    return 2147483600;
                }
                else {
                    return maxZIndex || 1;
                }
            }
        }));
}
//Get Max Z-Index

//Update cart link in menu

function setMaxZindexForModal() {
    let maxIndex = getMaxZIndex();
    $(".modal-backdrop").css("z-index", maxIndex + 1);
    $(".modal").css("z-index", maxIndex + 2);
}

function blurBackground() {
    $("div.body-content > div:not(#profile-pic-modal, #MemberOnlyAccessDenied), div.body-content > section, div.wawbreadcrumb, div#footer, header").css({ "filter": "blur(20px)", "-webkit-filter": "blur(20px)" });
}

function unBlurBackground() {
    $("div.body-content > div, div.body-content > section, div.wawbreadcrumb, div#footer, header").css({ "filter": "none", "-webkit-filter": "none" });
}

document.addEventListener('hide.bs.modal', function (event) {
    if (typeof (event.target.id) != "undefined" && event.target.id == "MemberOnlyAccessDenied") {
        unBlurBackground();
    }
});

document.addEventListener('show.bs.modal', function (event) {
    if (event && event.relatedTarget) {
        var returnUrl = $(event.relatedTarget).attr("data-href");
        var setLandingPage = false;
        var href = "/wawlogin?returnTo=" + encodeURIComponent(returnUrl);

        if (urlRegex.test(returnUrl)) {
            let domainThirdParty = (new URL(returnUrl));
            let domainSelf = (new URL(window.location.href));
            if (domainThirdParty != domainSelf) {
                setLandingPage = true;
            }
        }
        else if (isFile(returnUrl)) {
            setLandingPage = true;
        }

        if (setLandingPage) {
            var pageUrl = window.location.pathname + window.location.search;
            href = "/wawlogin?returnTo=" + encodeURIComponent(pageUrl);// + "%3FreturnToMemberPage%3D" + returnUrl;
            //uncomment the above and users will return to the same page with a landing page in query string which will be opened in a new tab.
            //But browsers will give security issues for opening a popup.
        }

        $(".memberonlyAccessDeniedLoginButton").attr("href", href);

        if (typeof (event.target.id) != "undefined" && event.target.id == "MemberOnlyAccessDenied") {
            blurBackground();
        }
    }
});

document.addEventListener('shown.bs.modal', function (event) {
    setMaxZindexForModal();
});

$(document).on("click", "[href^='#mktoForm_']", function (event) {
    event.preventDefault();
    isMarketoPopupTriggered = true;

    var btn_href = $(this).attr("href");
    var marketoFormShortId = "";
    var mktFormConfirmationMessage = "The form has been submitted successfully.";
    let marketoFormPromise = "";
    let successLink = parseMaketoPopupUrl(btn_href, "successLink");

    if (parseMaketoPopupUrl(btn_href, "message")) {
        mktFormConfirmationMessage = parseMaketoPopupUrl(btn_href, "message");
    }

    marketoFormPromise = getMarketoFormId(parseMaketoPopupUrl(btn_href, "mktoForm"));
    marketoFormPromise.then(
        function (value) {
            openMarketoModalForm(value, mktFormConfirmationMessage, successLink);
        },
        function (error) {
            console.error(error);
        }
    );
});

function parseMaketoPopupUrl(mktUrl, key) {
    const arr = mktUrl.split("#").filter(Boolean);
    const result = {};

    arr.forEach((item) => {
        const firstnderscoreIndex = item.indexOf("_");
        const key = item.substring(0, firstnderscoreIndex);
        const value = item.substring(firstnderscoreIndex + 1);
        result[key] = value;
    });

    return result[key];
}

//Get Marketo form Id
function getMarketoFormId(formName) {

    var body = { "formName": formName };

    return new Promise((resolve) => {
        $.ajax({
            url: '/get-marketo-id',
            type: "POST",
            data: body,
            success: function (data) {
                resolve(data);
            },
            error: function (error) {
                resolve(error);
            }
        });
    });
}
//Get Marketo form Id

function openMarketoModalForm(marketoFormShortId, mktFormConfirmationMessage, successLink) {
    var existingForm = MktoForms2.getForm(marketoFormShortId);
    $('#marketo-popup .modal-body .mkto-form-container').hide();
    if (!existingForm) {
        jQuery('<div>', {
            id: "mktoForm_" + marketoFormShortId + "_container",
            class: "mkto-form-container"
        }).appendTo('#marketo-popup .modal-body');
        jQuery('<form>', {
            id: "mktoForm_" + marketoFormShortId,
        }).appendTo('#marketo-popup .modal-body #mktoForm_' + marketoFormShortId + '_container');
        jQuery('<div>', {
            class: "marketo-response-message",
        }).appendTo('#marketo-popup .modal-body #mktoForm_' + marketoFormShortId + '_container');

        MktoForms2.loadForm(marketoBaseUrl, marketoSubscriptionId, marketoFormShortId, function (form) {
            form.onSuccess(function (values, followUpUrl) {
                isMarketoPopupTriggered = false;
                form.getFormElem().hide();
                $('#mktoForm_' + marketoFormShortId + '_container').find(".marketo-response-message").html(mktFormConfirmationMessage);

                if (successLink) {
                    var linkElementId = 'mktoForm_success_' + marketoFormShortId
                    if (successLink.startsWith("_video_")) {
                        $("<a/>", {
                            id: linkElementId,
                            href: successLink,
                            target: '_blank'
                        }).appendTo('#mktoForm_' + marketoFormShortId + '_container');
                        $('#' + linkElementId).click();
                        $('#video_videoModal').modal('show');
                        var marketoPopup = document.getElementById('marketo-popup')
                        var marketoPopupModal = bootstrap.Modal.getInstance(marketoPopup);
                        marketoPopupModal.hide();
                    } else {
                        window.open(successLink, "_blank")
                    }
                }

                return false;
            });
        });
    } else {
        $('#marketo-popup .modal-body #mktoForm_' + marketoFormShortId + '_container').show();
        onMarketoFormReady(existingForm);
    }
}

function onMarketoFormReady(form) {
    if (isMarketoPopupTriggered) {
        var popupModalId = "#" + form.getFormElem()[0]?.closest('.modal')?.id;
        var marketoPopupModal = bootstrap.Modal.getInstance(popupModalId);
        if (!marketoPopupModal) {
            var popupModalEl = document.querySelector(popupModalId);
            marketoPopupModal = new bootstrap.Modal(popupModalEl);
        }

        marketoPopupModal.show();
    }
}

function showToast(message, timeSpan, type) {
    $("#waw-toast .toast-body").html(message);
    if (typeof (type) != "undefined" && type == "success") {
        $("#waw-toast").addClass("toast-success");
    } else if (typeof (type) != "undefined" && type == "fail") {
        $("#waw-toast").addClass("toast-fail");
    } else {
        $("#waw-toast").removeClass("toast-success");
    }

    $('#waw-toast').css("z-index", getMaxZIndex() + 1);

    var wawToast = document.getElementById('waw-toast');
    var wawToastInstance = bootstrap.Toast.getOrCreateInstance(wawToast);

    if (!isNaN(timeSpan)) {
        wawToastInstance._config.delay = timeSpan;
    } else {
        wawToastInstance._config.delay = 7000;
    }

    wawToastInstance.show();
}

function updateCartLinkInMenu() {
    let isDonationProductinCart = cookieMap().get("isDonationProductinCart");

    if (isDonationProductinCart == undefined) {
        $('.cart-link-update').each((i, obj) => {

            const BC_CART_ID = localStorage.getItem('bcCartId');
            const HAS_MEMBERSHIP_IN_CART = localStorage.getItem('hasMembershipInCart');
            const CART_LENGTH = localStorage.getItem('cartLength');

            var cartLink = window.location.origin + "/cart?cartId=";

            if (BC_CART_ID) {
                cartLink = cartLink + BC_CART_ID;
            }

            if (HAS_MEMBERSHIP_IN_CART === 'true') {
                cartLink = cartLink + "&membership=true";
            }

            if (BC_CART_ID) {
                $(obj).attr('href', cartLink);
                $(obj).addClass("cart-link-update-contents");
                $(obj).attr("data-after", CART_LENGTH == 0 ? " " : CART_LENGTH);
            } else {
                $(obj).removeClass("cart-link-update-contents");
            }
        });
    } else {
        document.cookie = "isDonationProductinCart=; SameSite=Strict; path=/; Secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        localStorage.removeItem('bcCartId');
        localStorage.removeItem('hasMembershipInCart');
        localStorage.removeItem('cartLength');
    }
}

//Update cart link in menu

//Get Site Meta Data

function getSiteMetaData() {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: AppConfig_AppPaths_WebURL.value + '/site/meta-data?alias=' + SiteMetaDataAlias.value,
            type: "GET",
            success: function (data) {
                resolve(data)
            },
            error: function (error) {
                reject(error)
            }
        });
    });
}

//Get Site Meta Data

//Get AccessToken

function getBCAccessToken() {
    const BCClientId = $("#IDPClients_BC_ClientId").val();
    const BCClientScope = $("#IDPClients_BC_Scope").val();

    return getAccessToken(BCClientId, BCClientScope);
}

function getSFAccessToken() {
    const SFClientId = $("#IDPClients_SF_ClientId").val();
    const SFClientScope = $("#IDPClients_SF_Scope").val();

    return getAccessToken(SFClientId, SFClientScope);
}

function getAccessToken(clientId, clientScope) {

    var body = { "clientId": clientId, "clientScope": clientScope };

    return new Promise((resolve, reject) => {
        $.ajax({
            url: '/account/accesstoken',
            type: "POST",
            data: body,
            success: function (data) {
                resolve(data)
            },
            error: function (error) {
                reject(error)
            }
        });
    });
}

//Get AccessToken

//Pagination scripts - start

function getPageRange(start, end) {
    return Array(end - start + 1).fill().map((v, i) => i + start);
}

function pagination(current, length, delta = 2) {
    if (length <= 0) {
        let pages = [];
        return pages;
    }

    const range = {
        start: Math.round(current - delta / 2),
        end: Math.round(current + delta / 2)
    };

    if (range.start - 1 === 1 || range.end + 1 === length) {
        range.start += 1;
        range.end += 1;
    }

    let pages = current > delta ? getPageRange(
        Math.min(range.start, length - delta),
        Math.min(range.end, length)
    ) : getPageRange(1, Math.min(length, delta + 1));

    const withDots = (value, pair) => pages.length + 1 !== length ? pair : [value];

    if (pages[0] !== 1) {
        pages = withDots(1, [1, '...']).concat(pages);
    }

    if (pages[pages.length - 1] < length) {
        pages = pages.concat(withDots(length, ['...', length]));
    }

    return pages;
}

function scrollOnPagination(id) {
    var element = document.getElementById(id);
    if (element) {
        var headerOffset = getHeaderOffset();
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
}

function getHeaderOffset() {
    let headerOffset = 0;

    if (window.outerWidth < 992) {
        headerOffset = 100;

        if ($(".side-nav-bar-toggler").length > 0) {
            headerOffset += $(".side-nav-bar-toggler").outerHeight();
        }
    }
    else {
        headerOffset = 80;
    }

    return headerOffset;
}

//Pagination scripts - end

$(document).on("click", "[href^='_video_']", function (event) {
    event.preventDefault();
    var maxVideoHeight = 896;
    var videoHeight = window.innerHeight - 49;
    var addressValue = $(this).attr("href");
    addressValue = addressValue.replace('_video_', '');
    var $iframe = $('#videoEmbed');
    if ($iframe.length) {
        $iframe.attr('src', addressValue);
        if (videoHeight < maxVideoHeight) {
            $('#videoEmbed').css('height', videoHeight);
        }
        else {
            $('#videoEmbed').css('height', maxVideoHeight);
        }

        isVideoPopupOpened = true;
        return false;
    }
});


$('#video_videoModal').find('.btn-close').on("click", function (event) {
    var $iframe = $('#videoEmbed');
    if ($iframe.length) {
        $iframe.attr('src', '');
    }

    isVideoPopupOpened = false;
});

$("img").on("load", function () {
    loadVideoPlayButtons();
});

function loadVideoPlayButtons() {
    var popIndex = 0;
    $('a').each(function () {
        var value = $(this).attr('href');
        if (value != undefined) {
            if (value.startsWith('_video_')) {
                $(this).attr('data-bs-toggle', 'modal')
                $(this).attr('data-bs-target', '#video_videoModal')
                if ($(this).html().toLowerCase().includes('<img')) {
                    popIndex = popIndex + 1;
                    var imageElement = $(this).children();
                    if (imageElement.length > 0 && imageElement[0].naturalHeight != 0) {
                        var imgHeight = imageElement.innerHeight();
                        var isPlayInserted = $('#popVideoID' + popIndex);
                        if (imgHeight > 0 && !isPlayInserted.length) {
                            $(this).append('<a href="#" class="ColFixedWidthIcon" id="popVideoID' + popIndex + '" style="height:' + imgHeight + 'px;"><img src="/img/icons/video-play.svg" alt="play"/></a>');
                        }
                    }
                }
            }
        }
    });
}

// Validate Url
function isValidUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

function checkFlexGap() {
    var flex = document.createElement("div");
    flex.style.display = "flex";
    flex.style.flexDirection = "column";
    flex.style.rowGap = "1px";

    flex.appendChild(document.createElement("div"));
    flex.appendChild(document.createElement("div"));

    document.body.appendChild(flex);
    var isSupported = flex.scrollHeight === 1;
    flex.parentNode.removeChild(flex);

    return isSupported;
}

window.onscroll = function (event) {
    if (isFirstBrowserScroll && window.location.hash) {
        event.preventDefault();
        setTimeout(function () { scrollOnPagination(window.location.hash.replace('#', '')); }, 50);
        isFirstBrowserScroll = false;
    }

    isFirstBrowserScroll = false;

    hidePopoverOnScroll();
};

function triggerURLScroll() {
    if (window.location.hash) {
        setTimeout(function () {
            if (isFirstBrowserScroll) {
                window.scrollTo(0, window.pageYOffset > 1 ? window.pageYOffset - 1 : window.pageYOffset + 1);
            }
        }, 1500);
    }
}


// Case insensitive Array indexof
caseInsensitiveIndexOf = (arr, q) => arr.findIndex(item => q.toLowerCase() === item.toLowerCase());

isNullOrWhitespace = (input) => {
    return !input || !input.trim();
}

$(document).ready(function () {
    if (typeof widgetConsentsTobeSetup !== "undefined" && widgetConsentsTobeSetup.length > 0) {
        widgetConsentsTobeSetup.forEach(widgetConsentsAppSetup);
    }
});

cookieMap = () => {
    return new Map(document.cookie.split('; ').map(v => v.split(/=(.*)/s).map(decodeURIComponent)));
}

function widgetConsentsAppSetup(item) {

    let widgetConsentApp = Vue.createApp({
        data() {
            return {
                item: item,
                id: item.id,
                itemId: item.itemId,
                isLoading: true,
                isGivenConsent: item.isGivenConsent,
                consent: item.consent,
                isLoading: true,
                apiUrl: window.location.origin + "/WaW.Cms.Modules/CommonApi/AddWidgetInsights",
                canShowConsentForm: item.canShowConsentForm
            }
        },

        methods: {

            async logConsent(consentValue) {
                return new Promise((resolve) => {

                    var body =
                    {
                        "insightsItemId": this.itemId,
                        "consent": consentValue,
                        "pageUrl": window.location.pathname
                    };

                    $.ajax({
                        url: this.apiUrl,
                        type: "POST",
                        dataType: "json",
                        headers: {
                            RequestVerificationToken:
                                $('input:hidden[name="__RequestVerificationToken"]').val()
                        },
                        data: body,
                        success: function (data) {
                            resolve(data);
                        },
                        error: function (error) {
                            resolve(error);
                        },
                        complete: (data) => {
                            this.consent = consentValue;
                            this.closeConsent();
                            resolve(data);
                        }
                    });
                });
            },

            setConsentCookie() {
                if (cookieMap().get("widgetConsent") == undefined) {

                    document.cookie = "widgetConsent=" + this.itemId + ">" + this.consent + "; SameSite=Strict; path=/; Secure;";

                } else {
                    var existingCookie = cookieMap().get("widgetConsent");

                    existingCookie = existingCookie + "||" + this.itemId + ">" + this.consent;

                    document.cookie = "widgetConsent=" + existingCookie + "; SameSite=Strict; path=/; Secure;";
                }
            },

            closeConsent() {
                this.setConsentCookie();
                this.canShowConsentForm = false;
            }
        },

        computed: {

        },

        watch: {

        },

        created() {
            if (this.isGivenConsent) {
                this.canShowConsentForm = this.consent == "no";
            }
            else {
                var existingCookie = cookieMap().get("widgetConsent");
                if (existingCookie && existingCookie.indexOf(this.itemId) > -1) {
                    this.canShowConsentForm = existingCookie.split("||")[existingCookie.split("||").length - 1].split(">")[1] == "no";
                }
            }

            this.isLoading = false;
        },

        mounted() {

        },
    });

    widgetConsentsMountedApp['id_' + item.itemId] = widgetConsentApp.mount('#' + item.id + '_' + item.itemId);
}

function hidePopoverOnScroll() {
    try {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        popoverTriggerList.map(function (popoverTriggerEl) {
            if (popoverTriggerEl.attributes["data-bs-content"] != undefined) {
                var popover = bootstrap.Popover.getInstance(popoverTriggerEl);
                popover.hide();
            }
        });
    }
    catch (ex) {
        console.log(ex);
    }
}

function onFooterSubClose() {
    var cookieName = "hideFooterSubscription=true;";
    if ($("#footerSub").hasClass("sticky-bottom")) {
        cookieName = "makeFooterSubscriptionStatic=true;";
        $("#footerSub").removeClass("sticky-bottom")
    }

    var expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (24 * 60 * 60 * 1000));
    document.cookie = cookieName + " SameSite=Strict; path=/; Secure; expires=" + expiryDate.toUTCString(); +";";
    $("#footerSub").remove();
}

function onNewsLetterSubscribe() {
    let subscriptionEmail = $("#subscribe-email").val();
    var validationResult = validateSubscriptionEmail(subscriptionEmail);
    if (!validationResult.valid) {
        showToast(validationResult.error, 5000, "fail");
        return;
    }

    var body =
    {
        email: subscriptionEmail,
    };

    $('.loading-overflow-add-cart').show().css("z-index", getMaxZIndex());

    $.ajax({
        url: '/subscribe/news-letter',
        method: "POST",
        data: body,
        headers: {
            RequestVerificationToken:
                $('input:hidden[name="__RequestVerificationToken"]').val()
        },
        success: function (data) {
            $(".subscription-cta, .subscription-content, .footer-sub-close").addClass("d-none");
            $('.loading-overflow-add-cart').hide();
            if (data) {
                $(".subscription-success").removeClass("d-none");

                var expiryDate = new Date();
                expiryDate.setTime(expiryDate.getTime() + (24 * 60 * 60 * 1000));
                document.cookie = "makeFooterSubscriptionStatic=true; SameSite=Strict; path=/; Secure; expires=" + expiryDate.toUTCString(); +";";

                setTimeout(function () {
                    $("#footerSub").fadeOut(1000, function () { $("#footerSub").remove() });
                }, 5000);
            }
            else {
                $(".subscription-fail").removeClass("d-none");
            }

        },
        error: function (error) {
            $('.loading-overflow-add-cart').hide();
            console.log(error);
        }
    });
}

const validateSubscriptionEmail = (email) => {
    var isValidEmail = String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    if (!isValidEmail) {
        return { valid: false, error: "Please enter a valid email" };
    }
    return { valid: true, error: null };
};

function getCookie(name) {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        const cookieName = cookie[0];
        const cookieValue = cookie[1];

        if (cookieName === name) {
            return cookieValue;
        }
    }

    return null;
}

let oldXHROpen = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function (method, url, async) {

    if (url.includes("api/carts")) {
        this.addEventListener('loadend', (event) => {
            cartUpdated(url, method, event);
        });
    }

    return oldXHROpen.apply(this, arguments);
}

function cartUpdated(url, method, event) {

    let payload = {
        "cartOutput": event.target.responseText,
        "method": method,
        "url": url,
        "pageUrl": window.location.href
    };

    $.ajax({
        url: '/shopping/track-cart-update',
        type: "POST",
        data: payload,
        dataType: "json",
        headers: {
            RequestVerificationToken:
                $('input:hidden[name="__RequestVerificationToken"]').val()
        },
    });

}

const debounce = (mainFunction, delay) => {
    let timer;
    return function (...args) {
        clearTimeout(timer);

        timer = setTimeout(() => {
            mainFunction(...args);
        }, delay);
    };
};

function sortArrayWithArray(arrayToSort, sortOrder) {

    const sorter = (a, b) => {
        return sortOrder.indexOf(a) - sortOrder.indexOf(b);
    }

    arrayToSort.sort(sorter);
    return arrayToSort;
}


Number.prototype.localizeCurrency = function () {
    try {
        let match = currencyPattern.match(/{(\d+):?C(\d*)}/);
        let fractionDigits = 2;
        if (match) {
            fractionDigits = match[2];
        }

        let currencyFormatter = Intl.NumberFormat(currentCulture, {
            style: 'currency',
            currency: currencyText,
            minimumFractionDigits: fractionDigits ?? 2
        });

        var indexOfText = currencyPattern.trim().indexOf("{1}");

        if (indexOfText == 0) {
            return `${currencyText} ${currencyFormatter.format(this)}`;
        } else if (indexOfText == -1) {
            return `${currencyFormatter.format(this)}`;
        } else {
            return `${currencyFormatter.format(this)} ${currencyText}`;
        }
    }
    catch (err) {
        console.log(err);
    }

    return "";
}

function convertTimeZone(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}