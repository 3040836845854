let membershipExpiredApp = Vue.createApp({
    data() {
        return {
            wawUser: window.wawUser
        }
    },
    methods: {
        openPopup() {
            $('#membership_expired_popup').modal('show');
            localStorage.setItem('MembershipRenewalAlertShown', new Date().getTime());
        },

        closePopup() {
            $('#membership_expired_popup').modal('hide');
        },

        getHourDiff(setupTime) {
            return Math.abs(new Date().getTime() - setupTime) / 36e5;
        },

        setDontShowAgain() {
            $.ajax({
                url: window.location.origin + '/profile/dont-show-membership-expiry-alert',
                type: "POST",
                dataType: "json",
                data: {
                    personId: this.wawUser.personId
                }
            });

            this.closePopup();
        }
    },
    computed: {

    },
    created() {
        let setupTime = localStorage.getItem('MembershipRenewalAlertShown');

        let isProperTimeToShow = setupTime === null ? true : this.getHourDiff(setupTime) >= 24;

        if (isProperTimeToShow && this.wawUser.isMembershipOnGracePeriod && this.wawUser.shouldShowMembershipExpiredAlert) {
            setTimeout(() => {
                this.openPopup();
            }, 5000);
        }
    },
});

var membershipExpiredMountedApp = membershipExpiredApp.mount('#membership_expired_popup');