if (typeof Ecommerce !== 'undefined') {
    Ecommerce = Ecommerce ? Ecommerce : {};
} else {
    Ecommerce = {};
}
Ecommerce.isReady = false;

Ecommerce.init = function (tracker) {
    Ecommerce.isReady = true;
}

Ecommerce.actions = [
    'click',
    'detail',
    'add',
    'remove',
    'checkout',
    'checkout_option',
    'purchase',
    'refund',
    'promo_click'
];

// Impressions
Ecommerce.productList = function (productObject) {
    try {

        gtag("event", "view_item_list", {
            item_list_id: productObject.listId,
            item_list_name: productObject.listName,
            items: [{
                item_id: productObject.sku,
                item_name: productObject.name,
                item_category: productObject.category,
                item_list_id: productObject.listId,
                item_list_name: productObject.listName,
                price: productObject.price
            }]
        });


        window.appInsights.trackEvent({
            "name": "productImpression",
            "properties": {
                "Product": impressionObject,
                "EmailId": window.wawUserEmail,
                "PersonId": window.wawUserPersonId
            }
        });

        window.appInsights.flush();
    } catch (ex) {
    }
}

Ecommerce.productClick = function (productObject) {
    try {
        gtag("event", "select_item", {
            item_list_id: productObject.listId,
            item_list_name: productObject.listName,
            items: [
                {
                    item_id: productObject.sku,
                    item_name: productObject.name,
                    item_category: productObject.category,
                    item_list_id: productObject.listId,
                    item_list_name: productObject.listName,
                    price: productObject.price
                }
            ]
        });
    } catch (ex) {
    }
}

Ecommerce.productDetail = function (productObject) {
    try {
        gtag("event", "view_item", {
            currency: currencyText,
            value: productObject.price,
            items: [
                {
                    item_id: productObject.sku,
                    item_name: productObject.name,
                    item_category: productObject.category,
                    item_list_id: productObject.listId,
                    item_list_name: productObject.listName,
                    price: productObject.price
                }
            ]
        });
    } catch (ex) {
    }
}

//Find Product and send events
Ecommerce.findProductAndSend = function (productsArray, productId, event, quantity, mainProductData) {
    try {
        var product = productsArray.find(f => f.id == productId || f.product_id == productId);

        if (product) {
            var listName = "";
            var listId = "";
            var category = "";
            var price = "";
            var description = "";

            if (mainProductData) {
                listName = mainProductData.name;
                listId = mainProductData.id;
            }

            if (product.categories && product.categories.length > 0) {
                category = window.allCategories.filter(f => product.categories.includes(f.id))[0].name;
            }

            if (product.price) {
                price = product.price;
            } else if (product.list_price) {
                price = product.list_price;
            }

            if (product.description) {
                description = product.description;
            }

            var productObject = {
                "id": product.id,
                "name": product.name,
                "sku": product.sku,
                "description": description,
                "price": price,
                "category": category,
                "quantity": quantity,
                "listName": listName,
                "listId": listId
            }

            if (event == "productClick") {
                Ecommerce.productClick(productObject);
            }
            else if (event == "productDetail") {
                Ecommerce.productDetail(productObject);
            }
            else if (event == "productAddToCart") {
                Ecommerce.cartAdd(productObject);
            }
            else if (event == "productRemoveFromCart") {
                Ecommerce.cartRemove(productObject);
            }

            window.appInsights.trackEvent({
                "name": event,
                "properties": {
                    "Product": productObject,
                    "EmailId": window.wawUserEmail,
                    "PersonId": window.wawUserPersonId
                }
            });

            window.appInsights.flush();
        }
    } catch (ex) {
    }
}

// Cart
Ecommerce.cartAdd = function (productObject) {
    try {
        gtag("event", "add_to_cart", {
            currency: currencyText,
            value: productObject.price,
            items: [
                {
                    item_id: productObject.sku,
                    item_name: productObject.name,
                    item_category: productObject.category,
                    item_list_id: productObject.listId,
                    item_list_name: productObject.listName,
                    price: productObject.price,
                    quantity: productObject.quantity
                }
            ]
        });
    }
    catch (ex) {

    }
}

Ecommerce.cartRemove = function (productObject) {
    try {
        gtag("event", "remove_from_cart", {
            currency: currencyText,
            value: productObject.price,
            items: [
                {
                    item_id: productObject.sku,
                    item_name: productObject.name,
                    item_category: productObject.category,
                    item_list_id: productObject.listId,
                    item_list_name: productObject.listName,
                    price: productObject.price,
                    quantity: productObject.quantity
                }
            ]
        });
    }
    catch (ex) {

    }
}

Ecommerce.cartView = function (totalAmount, items) {
    try {
        gtag("event", "view_cart", {
            currency: currencyText,
            value: totalAmount,
            items: items
        });
    }
    catch (ex) {

    }
}