var isCustomPopupOpen = false;
function CustomPopup(selector) {
    this.selector = selector;
    this.popupModalEl = document.querySelector(selector);
    this.popupModal = new bootstrap.Modal(this.popupModalEl);
    this.showPopupFlag = true;
    this.popupDetailObj = {};
    this.idleTimer = {};
    this.popupWindowClosed = {};

    // If a longer string is found in array2, the function immediately returns false. If no longer string is found in array2, the function returns true.
    this.hasLengthierString = function (array1, array2) {
        var longestString = array1[0];

        for (var i = 1; i < array1.length; i++) {
            if (array1[i].length > longestString.length) {
                longestString = array1[i];
            }
        }

        for (var j = 0; j < array2.length; j++) {
            if (array2[j].length > longestString.length) {
                return false;
            }
        }

        return true;
    }
}



CustomPopup.prototype.displayPopup = function(popupObjList) {
    for (var i = 0; i < popupObjList.length; i++) {
        var popupDetail = popupObjList[i];
        this.popupDetailObj = popupDetail;
        if (popupDetail.isEnabled && !this.hasPopupAlreadyShown(popupDetail.id) && this.canDisplayedOnThisPage(popupDetail)) {
            if (popupDetail.showAfter == "scroll") {
                this.displayPopUpAfterScroll(popupDetail.scrollPercentage);
            } else if (popupDetail.showAfter == "delay") {
                this.displayPopUpAfterTime(popupDetail.delayTime);
            } else {
                this.displayPopupAfterIdleTime(popupDetail.idleTime);
            }

            break;
        }
    }
}

CustomPopup.prototype.displayPopUpAfterTime = function (delay) {
    var self = this;
    setTimeout(function () {
        self.showPopupModal();
    }, delay * 1000);
};

CustomPopup.prototype.displayPopUpAfterScroll = function (percentage) {
    var self = this;
    var windowHeight = window.innerHeight;
    var documentHeight = document.body.clientHeight;

    window.addEventListener('scroll', function () {
        var scrollPercentage = (window.scrollY / (documentHeight - windowHeight)) * 100;

        if (scrollPercentage >= percentage) {
            self.showPopupModal();
        }
    });
};

CustomPopup.prototype.displayPopupAfterIdleTime = function (idleTime) {
    var self = this;
    this.resetIdleTimer(idleTime);
    document.addEventListener('mousemove', function () {
        self.resetIdleTimer(idleTime);
    });
    document.addEventListener('keydown', function () {
        self.resetIdleTimer(idleTime);
    });
    document.addEventListener('scroll', function () {
        self.resetIdleTimer(idleTime);
    });
};

CustomPopup.prototype.resetIdleTimer = function (idleTime) {
    var self = this;
    clearTimeout(this.idleTimer);
    this.idleTimer = setTimeout(function () {
        self.showPopupModal();
        document.removeEventListener('mousemove', function () {
            self.resetIdleTimer();
        });
        document.removeEventListener('keydown', function () {
            self.resetIdleTimer();
        });
        document.removeEventListener('scroll', function () {
            self.resetIdleTimer();
        });
    }, idleTime * 1000);
};

CustomPopup.prototype.hasPopupAlreadyShown = function (id) {
    var popupShown = false;
    // As we need to get old cookies of survey popup, we didn't changed the cookie name format.
    var popupCookie = getCookie('survey_' + id + '_completed');

    if (popupCookie) {
        popupShown = true;
    }

    return popupShown;
}

CustomPopup.prototype.canDisplayedOnThisPage = function (popupDetail) {
    var self = this;
    var currentPageURL = window.location.pathname;

    if (popupDetail.pagesToBeAvoided.length === 0 && popupDetail.pagesToBeDisplayed.length === 0) {
        return true;
    }

    var isInAvoidedPaths = popupDetail.pagesToBeAvoided.includes(currentPageURL);

    if (isInAvoidedPaths) {
        return false;
    }

    var isInDisplayedPaths = popupDetail.pagesToBeDisplayed.includes(currentPageURL);

    if (isInDisplayedPaths) {
        return true;
    }

    var avoidedPathsMatch = [];
    popupDetail.pagesToBeAvoided.map(function (path) {
        if (self.matchWildcardPath(path, currentPageURL)) {
            avoidedPathsMatch[avoidedPathsMatch.length] = path;
        }
    });

    var displayedPathsMatch = [];
    popupDetail.pagesToBeDisplayed.map(function (path) {
        if (self.matchWildcardPath(path, currentPageURL)) {
            displayedPathsMatch[displayedPathsMatch.length] = path;
        }
    });

    if (avoidedPathsMatch.length > 0 && displayedPathsMatch.length == 0) {
        return false;
    }

    if (avoidedPathsMatch.length == 0 && displayedPathsMatch.length > 0) {
        return true;
    }

    if (avoidedPathsMatch.length == 0 && displayedPathsMatch.length == 0) {
        return true;
    }

    if (avoidedPathsMatch.length != 0 && displayedPathsMatch.length != 0) {
        return hasLengthierString(displayedPathsMatch, avoidedPathsMatch)
    }

    return true;
}

CustomPopup.prototype.showPopupModal = function () {
    if (this.showPopupFlag && (typeof (isVideoPopupOpened) != "undefined" || !isVideoPopupOpened) && !isCustomPopupOpen) {
        var popupModalObj = bootstrap.Modal.getInstance(this.selector);
        popupModalObj.show();
        this.showPopupFlag = false;
        isCustomPopupOpen = true;
    }
};

CustomPopup.prototype.hidePopupModal = function () {
    var popupModalObj = bootstrap.Modal.getInstance(this.selector);
    popupModalObj.hide();
    isCustomPopupOpen = false;
}

CustomPopup.prototype.matchWildcardPath = function(pattern, path) {
    var regexPattern = '^' + pattern.replace(/\*/g, '.*') + '$';
    var regex = new RegExp(regexPattern);
    return regex.test(path);
}

CustomPopup.prototype.setPopupCookie = function (action) {
    var expireDays = 1;

    if (action == "cancel") {
        expireDays = this.popupDetailObj.showAfterNDaysOfCancel;
    } else if (action == "submission") {
        expireDays = this.popupDetailObj.showAfterNDaysOfSubmission;
    }

    document.cookie = 'survey_' + this.popupDetailObj.id + '_completed=' + new Date().toUTCString() + '; path=/; SameSite=Strict; Secure; expires=' + new Date(Date.now() + expireDays * 24 * 60 * 60 * 1000).toUTCString() + ';';
}