
function initializeProductDetailsPopup(productId) {
    let productDetailsApp = Vue.createApp({
        data() {
            return {
                mainProductId: productId,
                productDetail: [],
                productCfs: null,
                isContactSponsorPopup: false,
                isLoading: false,
                showPopup: false
            }
        },
        methods: {
            getProductDetailsByBcId(bcId) {
                getBCAccessToken().then((accessToken) => {
                    $.ajax({
                        url: API + "/api/products/course-details/" + bcId,
                        method: 'GET',
                        dataType: "json",
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                        },
                        success: function (result) {
                            productDetailsMountedApp['id_' + productId].productDetail.push(result);
                            productDetailsMountedApp['id_' + productId].isLoading = false;
                        }
                    })
                });
            },

            getWebsiteUrl(product) {
                let website_url = product.customer_Contact_URL__c?.trim();
                if (website_url) {
                    if (!isValidUrl(website_url)) {
                        website_url = "https://" + website_url;
                    }
                }

                return website_url;
            },

            getSessionDate(start, end) {
                let text = '';

                if (start?.trim().length > 0) {
                    text = start;

                    if (end?.trim().length > 0 && start !== end) {
                        text += ` - ${end}`;
                    }
                } else if (end?.trim().length > 0) {
                    text = end;
                }

                return text;
            },

            closeProductDetails() {
                $("#productDetails_" + this.mainProductId + " #product-details-popup").modal('hide');
            },
        },
        computed: {

        },
        created() {

        },
    });

    productDetailsMountedApp['id_' + productId] = productDetailsApp.mount('#productDetails_' + productId);
}